import { Pipe, PipeTransform } from '@angular/core';
import { IBlockchainLedgerWalletDetails } from '../models/xls-20-nfts/blockchain-ledger-wallet-details';

@Pipe({name: 'BlockchainWalletName'})
export class BlockchainWalletNamePipe implements PipeTransform {
  transform(blockchainLedgerWalletDetails: IBlockchainLedgerWalletDetails | undefined): string {

    if (!blockchainLedgerWalletDetails) {
      return "";
    }

    if (blockchainLedgerWalletDetails.username) {
      return blockchainLedgerWalletDetails.username;
    } else if (blockchainLedgerWalletDetails.service) {
      return blockchainLedgerWalletDetails.service;
    } else if (blockchainLedgerWalletDetails.varifiedDomain) {
      return blockchainLedgerWalletDetails.varifiedDomain;
    }

    return blockchainLedgerWalletDetails.address.substring(0, 10) + "...";

  }
}
