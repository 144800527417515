import { ISecurityIdentityDetails } from "./security-identity-details";

export interface IUserDetails2 {

  userGuid: string | undefined
  emailAddress: string,
  updateByEmail: boolean,
  securityIdentityDetails: ISecurityIdentityDetails[] | undefined

}
