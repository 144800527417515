import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecurityService, ServiceResultStatus } from '../../services';

@Component({
  selector: 'app-account-details-prompt',
  templateUrl: './account-details-prompt.component.html',
  styleUrls: ['./account-details-prompt.component.css']
})
export class AccountDetailsPromoptComponent implements OnInit {

  waitingMessage: string | undefined;

  detailsForm = new FormGroup({
    accountGuid: new FormControl(''),
    username: new FormControl('', Validators.required),
    emailAddress: new FormControl('', [Validators.required, Validators.email]),
    optInForMarketingEmails: new FormControl(false)
  });

  @Input()
  isFirstSignIn = false;

  @Output()
  updated = new EventEmitter<void>();

  @Output()
  skipped = new EventEmitter<void>();

  constructor(private readonly securityService: SecurityService) { }

  ngOnInit(): void {
    this.securityService.getAccountDetails().subscribe(result => {
      if (result.status === ServiceResultStatus.Success && result.data && this.detailsForm) {
        let controls = this.detailsForm.controls;
        controls['accountGuid'].setValue(result.data.accountGuid!);
        controls['username'].setValue(result.data.accountUsername ?? '');
        controls['emailAddress'].setValue(result.data.emailAddress);
        controls['optInForMarketingEmails'].setValue(result.data.canSendMarketingEmails);
      }
    });
  }

  skip() {
    this.skipped.emit();
  }

  update() {
    if (this.detailsForm && this.detailsForm.valid) {
      let controls = this.detailsForm.controls;
      this.waitingMessage = 'UPDATING DETAILS';
      this.securityService.updateAccountDetails({
        accountGuid: controls['accountGuid'].value ?? '',
        accountUsername: controls['username'].value ?? '',
        emailAddress: controls['emailAddress'].value ?? '',
        canSendMarketingEmails: controls['optInForMarketingEmails'].value ?? false,
        securityIdentityDetails: undefined,
        accountName: undefined,
        userGuid: undefined,
        loggedInWithSecurityIdentityDetails: undefined,
        paymentInstrumentDetails: undefined
      }).subscribe(result => {
        if (result.status === ServiceResultStatus.Success) {
          this.waitingMessage = 'DETAILS UPDATED';
        }
        else {
          this.waitingMessage = 'UPDATE FAILED - PLEASE TRY AGAIN';
        }
        window.setTimeout(() => {
          this.waitingMessage = undefined;
          this.updated.emit();
        }, 1000);
      });
    }
  }
}
