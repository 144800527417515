import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IAccountDetails } from '../shared/models';
import { NotificationService, NotificationType, SecurityService, ServiceResultStatus } from '../shared/services';
import { UrlService } from '../shared/services/url.service';
import { UiService } from '../shared/services/ui.service';

@Component({
  selector: 'app-header-v2',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private clickOutside = new EventEmitter<void>();

  accountDetails?: IAccountDetails;
  showAccountDetails = false;
  navigationOpen = false;

  subscriptions: Subscription[] = [];

  get userName() {
    return this.accountDetails?.accountUsername;
  }

  get userEmail() {
    return this.accountDetails?.emailAddress;
  }

  get isSignedIn() {
    return this.securityService.isSignedIn;
  }

  constructor(
    private securityService: SecurityService,
    private elementRef: ElementRef,
    private notificationService: NotificationService,
    public urlService: UrlService,
    private uiService: UiService
  ) { }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const isClickedInside = this.elementRef.nativeElement.contains(clickedElement);

    if (!isClickedInside) {
      this.showAccountDetails = false;
    }
  }

  ngOnInit(): void {
    this.loadDetails();

    this.subscriptions.push(this.notificationService.listenFor(NotificationType.SignOut).subscribe(() => {
      this.accountDetails = undefined;
    }));

    this.subscriptions.push(this.notificationService.listenFor(NotificationType.SignInApproved).subscribe(() => {
      this.loadDetails();
    }));
  }

  signOut() {
    this.securityService.signOut(true);
  }

  signIn() {
    this.uiService.openSignInModal();
  }

  loadDetails() {
    this.securityService.getAccountDetails().subscribe(result => {
      if (result.status === ServiceResultStatus.Success) {
        this.accountDetails = result.data;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
