<div class="w-100 position-relative" [ngClass]="{ 'position-relative': !isFirstSignIn }">
  <form [formGroup]="detailsForm" (ngSubmit)="update()">
    <div>
      <div>
        <div class="input-group">
          <label htmlFor="user-name">
            Username<span>*</span>
          </label>
          <input type="text" id="user-name" formControlName="username" autocomplete="off" spellcheck="false" placeholder="Your Username" />
        </div>
        <div class="input-group">
          <label htmlFor="user-email">
            E-mail<span>*</span>
          </label>
          <input type="email" id="user-email" formControlName="emailAddress" autocomplete="off" spellcheck="false" placeholder="Your Email" />
        </div>
      </div>
    </div>
    <div>
      <div class="input-group">
        <app-checkbox [control]="detailsForm.controls['optInForMarketingEmails']" label="Please send me updates by e-mail"></app-checkbox>
      </div>
    </div>
    <div class="button-group d-flex align-items-center" [ngClass]="{ 'justify-content-center': isFirstSignIn }">
      <button class="button button--primary" type="submit" [disabled]="!detailsForm.valid || !!waitingMessage">
        Update
      </button>
      <button class="button button--tertairy" type="button" *ngIf="isFirstSignIn" (click)="skip()">
        Skip for Now
      </button>
    </div>
  </form>
  <app-waiting [message]="waitingMessage" [isWaiting]="!!waitingMessage"></app-waiting>
</div>
