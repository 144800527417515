<app-waiting
  [message]="'Loading order history'"
  [isWaiting]="isLoading"
  [fullScreen]="false"
  *ngIf="isLoading">
</app-waiting>

<ng-container *ngIf="!isLoading">
  <div *ngIf="transactions && transactions.length > 0; else noTransaction">
    <table class="transaction-desktop">
      <thead>
        <tr>
          <th>Created</th>
          <th>Description</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of transactions">
          <td>{{ transaction.created | date : 'dd/MM/yy HH:mm:ss' }}</td>
          <td>{{ transaction.description }}</td>
          <td>{{ transaction.quantity }}</td>
          <td>{{ { value: transaction.amount, currencyGuid: transaction.currencyGuid } | currencyAmount }}</td>
          <td>{{ transaction.accountTransactionStatusName }}</td>
        </tr>
      </tbody>
    </table>

    <div class="transaction-mobile" [ngClass]="{'transaction-mobile--show': showMobileDetails}">
      <div>
        <h5 class="head head--small">Order history</h5>
        <i class="fa-solid fa-xmark" (click)="showMobileDetails = false"></i>
      </div>
      <ul>
        <li *ngFor="let transaction of transactions; let i = index">
          <ng-container *ngIf="i < 3 || showMobileDetails">
            {{ transaction.created | date : 'dd/MM/yy HH:mm:ss' }}<br/>
            {{ transaction.description }}<br/>
            {{ transaction.quantity }}<br/>
            <span *ngIf="showMobileDetails">{{ { value: transaction.amount, currencyGuid: transaction.currencyGuid } | currencyAmount }}</span><br/>
            <span *ngIf="showMobileDetails">{{ transaction.accountTransactionStatusName }}</span>
          </ng-container>
        </li>
      </ul>

      <button type="button" class="button button--tertairy-alt" (click)="showMobileDetails = true">Show all details</button>
    </div>

  </div>

  <ng-template #noTransaction>

    <p class="empty-state-para">We're sorry! You don't have any order history.</p>

    <div class="button-group">
      <a class="button button--secondary" href="{{environment.marketingSite}}xspectar-tokens.html">Buy xSpectar Tokens</a>
      <a class="button button--secondary" routerLink="/land-sale-purchase">Buy land</a>
      <a class="button button--secondary" routerLink="/marketplace">Go to marketplace</a>
    </div>
  </ng-template>
</ng-container>
