import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { mapServiceResult } from './';
import { IAssetOwnershipStatistic } from '../models';

@Injectable({
  providedIn: 'root',
 })
 export class MarketplaceStatisticsService {

    constructor(
        private httpClient: HttpClient) { }

    getAssetOwnershipStatistics() {
        return this.httpClient
                    .get<IAssetOwnershipStatistic[]>(`${environment.apiUrl}statistics/assets/ownership?assetSeriesGuid=${environment.agentAssetSeriesGuid}`)
                    .pipe(mapServiceResult);
    }

    getPlotLandAssetOwnershipStatistics() {
      return this.httpClient
                  .get<IAssetOwnershipStatistic[]>(`${environment.apiUrl}statistics/assets/ownership?assetCollectionGuid=${environment.landSalePlotsAssetCollectionGuid}`)
                  .pipe(mapServiceResult);
  }
 }
