export interface IBasePurchaseInstruction {
    method: number,
    transactionGuid: string,
    providerGuid: string,
    isValid: boolean,
    errorMessage: string,
    assetGuid: string,
}
export interface IXummPurchaseInstruction  extends IBasePurchaseInstruction {
    data: {
        qrCodeUrl: string,
        qrCodeImage: string,
    }
}

export interface IStripePurchaseInstruction extends IBasePurchaseInstruction {
    data: {
        clientSecret?: string
    }
}

export interface IHederaPurchaseInstruction extends IBasePurchaseInstruction {
    data: {
        network: string,
        toAccount: string,
        fromAccount: string,
        amount : number,
        memo: string,
        transactionGuid: string
    }
}

export interface IGeneralEvmPurchaseInstruction extends IBasePurchaseInstruction {
    data: {
        toAccount: string,
        fromAccount: string,
        amount : string,
        externalReference: string,
        paymentInstrumentGuid: string,
        isPrimary: boolean,
        transactionGuid: string
    }
}


