import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'MiniEvmWalletAddress'})
export class MiniEvmWalletAddressPipe implements PipeTransform {
  transform(walletAddress: string): string {

    return walletAddress.substring(0, 6) + "..." + walletAddress.substring(walletAddress.length - 6, walletAddress.length);

  }
}
