export enum NftCreateOfferProgressStatus {
    Failed,
    Complete,
    Processing
}

export interface INftCreateOfferProgress {
    progressStatus: NftCreateOfferProgressStatus,
    transactionGuid: string
}
  