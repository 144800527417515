<app-waiting
  [message]="'Loading vaults'"
  [isWaiting]="isLoading"
  [fullScreen]="false"
  *ngIf="isLoading">
</app-waiting>

<ng-container *ngIf="!isLoading">
  <div *ngIf="displayedVaultAssets.length > 0; else noVaultAssets">
    <ul class="xs-lock" *ngFor="let vaultAsset of displayedVaultAssets">
      <li class="xs-lock__item">
        <strong>Locked tokens</strong>
        {{ vaultAsset.amount }}  XSPECTAR
      </li>
      <li class="xs-lock__item">
        <strong>Maturity date</strong>
        {{ vaultAsset.matures | date : 'dd/MM/yy' }}
      </li>
      <li class="xs-lock__item">
        <strong>Reward - {{vaultAsset.reward.interest}}% ap</strong>
        {{ vaultAsset.yeild }} XSPECTAR
      </li>
      <li class="xs-lock__item">
        <strong>Total</strong>
        {{ vaultAsset.finalAmount }}
      </li>
      <!-- <li class="xs-lock__item">
        <button class="button button--tertairy">Release my tokens</button>
      </li> -->
    </ul>
  </div>

  <ng-template #noVaultAssets>
    <p class="empty-state-para">We're sorry you dont have any tokens locked in the XS Lock Vault</p>

    <div class="button-group">
      <a class="button button--secondary" routerLink="/xs-lock">Lock your tokens</a>
      <a class="button button--secondary" href="{{environment.marketingSite}}xspectar-tokens.html">Buy xSpectar tokens</a>
    </div>
  </ng-template>
</ng-container>
