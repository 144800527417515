import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-slide-v2',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponentV2 implements OnInit {
  @ViewChild('container') containerRef?: ElementRef

  @Input()
  slideIndex: number = 0;

  @Input()
  slideCount: number = 2;

  get gridTemplateRows() {
    let rows = '';
    for (let i = 1; i <= this.slideCount; i++) {
      if (i !== this.slideIndex + 1) {
        rows += '0 ';
      } else {
        rows += '1fr ';
      }
    }
    return rows.trim();
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.updateGrid();    
  }

  ngOnChanges() {
    this.updateGrid();
  }

  updateGrid() {
    const container = this.containerRef?.nativeElement.querySelector('div');

    if (container) {
      const contentElements = container.children;

      // Set the grid row for each content element
      for (let i = 0; i < contentElements.length; i++) {
        const contentElement = contentElements[i] as HTMLElement;
        contentElement.style.gridRow = `${i + 1} / ${i + 2}`;

        if (i === this.slideIndex) {
          contentElement.classList.add('active');
        } else {
          contentElement.classList.remove('active');
        }
      }
    }
  }
}
