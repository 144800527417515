<div class="main-container">

  <app-header-v2></app-header-v2>

  <main>
    <router-outlet></router-outlet>
  </main>

  <app-footer-v2></app-footer-v2>

</div>

<app-sign-in-v2></app-sign-in-v2>
