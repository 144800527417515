import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountLayoutComponent } from './components/layout/layout.component';
import { DetailsComponent } from './components/details/details.component';
import { AssetsComponent } from './components/assets/assets.component';
import { LandComponent } from './components/land/land.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { XsLockVaultComponent } from './components/xs-lock-vault/xs-lock-vault.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { AccountRoutingNewModule } from './account-routing.module';
import { SharedModuleV2 } from '../shared/shared.module';
import { LinkAccountsComponent } from './components/link-accounts/link-accounts.component';
import { PaymentsComponent } from './components/payments/payments.component';

declare let bootstrap: any;
@NgModule({
  declarations: [
    AccountLayoutComponent,
    DetailsComponent,
    AssetsComponent,
    LandComponent,
    OrderHistoryComponent,
    XsLockVaultComponent,
    NavigationComponent,
    LinkAccountsComponent,
    PaymentsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModuleV2,
  ],
  exports: [
    AccountRoutingNewModule,
  ]
})
export class AccountNewModule { }
