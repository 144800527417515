import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ICurrencyAmount } from '../models/marketplace';

@Pipe({name: 'currencyAmount'})
export class CurrencyAmountPipe implements PipeTransform {
  transform(currencyAmount: ICurrencyAmount): string {

    switch(currencyAmount.currencyGuid) {
      case environment.xrpCurrencyGuid:
        return `${currencyAmount.value} XRP`;
      case environment.xSpectarCurrencyGuid:
        return `${currencyAmount.value} xSPECTAR`;
      case environment.usdCurrencyGuid:
        return `${currencyAmount.value} USD`;
      case environment.hbarCurrencyGuid:
        return `${currencyAmount.value} HBAR`;
      case environment.ethereumCurrencyGuid:
        return `${currencyAmount.value} ETH`;
      case environment.flareCurrencyGuid:
        return `${currencyAmount.value} FLR`;
      case environment.songbirdCurrencyGuid:
        return `${currencyAmount.value} SGB`;
      case environment.xdcCurrencyGuid:
        return `${currencyAmount.value} XDC`;
    }
    return `${currencyAmount.value}`;

  }
}
