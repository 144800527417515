import { catchError, Observable, of } from 'rxjs';
import { ServiceResultStatus } from './service-result-status';

export class ServiceResult<TDataType = void> {
  status!: ServiceResultStatus;
  message: string | undefined;
  data: TDataType | undefined;
}

export function mapServiceResult<TDataType = void>(observable: any): Observable<ServiceResult<TDataType>> {
  return observable.pipe(catchError(error => {
    return of({
      status: error.status in ServiceResultStatus ? error.status : ServiceResultStatus.Error,
      message: error?.error?.message ?? error?.message ?? error?.statusText,
      data: undefined } as ServiceResult<TDataType>);
  }));
};
