import { IMarketplaceListing } from "../../marketplace";
import { IAssetSeries } from "../asset-series";

export interface IVaultListingDetail {
  months: number;
  rewards: IVaultAssetReward[];
}

export interface IVaultAssetReward {
  interest: number;
  min: number;
  max: number | null;
  freeAssets: string[];
  freeAssetsSeries: IAssetSeries[];
}

export interface IRewardAssetMetadata{
  issuedReason: string
  qualifyingAssetId: number;
  avaliableAt: Date;
}

export interface IVaultAssetMetadata {
  amount: number;
  created: Date;
  matures: Date;
  yeild: number;
  finalAmount: number;
  reward: IVaultAssetReward;
  isAwaitingRelease: boolean;
}


