import { Pipe, PipeTransform } from '@angular/core';
import { IXls20NftDetails } from '../models/xls-20-nfts';

@Pipe({
  name: 'nftSearch'
})
export class NftSearchPipe implements PipeTransform {

  transform(value: IXls20NftDetails[], searchText: string): IXls20NftDetails[] {
    if (searchText == undefined || searchText.length === 0) {
      return value;
    }

    return value
      .filter(nft => {
        let found = false;
        if (nft.name?.includes(searchText) || nft.description?.includes(searchText)) {
          found = true;
        }

        if (!found) {
          found = nft.attributes.some( attr => {
            return attr.value.includes(searchText);
          });
        }

        if (found) {
          return true;
        }

        return false;

    });
  }

}
