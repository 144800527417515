import { IAssetCollection } from "./asset-collection";

export interface IAssetSeries {
    assetCollection: IAssetCollection | null,
    assetSeriesGuid: string,
    assetSeriesName: string,
    assetSeriesSize: number | null,
    assetsAvailable: number | null,
    created: Date,
    createByGuid: string,
    createdByName: string,
    modified: Date | null,
    modifiedByGuid: string | null,
    modifiedByName: string | null
}