import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceResult } from './results/service-result';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { ServiceResultStatus } from './results/service-result-status';

@Injectable({
  providedIn: 'root',
})
export class LandNFTImageService {
  constructor(private httpClient: HttpClient) {}

  async getLandNFTImage(parcelName: string) {
    //split the parcel name to get the region, zone and parcel
    let parcelDetails = parcelName.split('_');

    var result = await firstValueFrom(
      this.httpClient.get<string>(
        `${environment.landImageApiUrl}image?region=${parcelDetails[0]}&zone=${parcelDetails[1]}&parcel=${parcelDetails[2]}`
      )
    );

    return result;
  }
}
