import { IMarketplaceListing } from "../../marketplace";

export interface ILandSaleListing {
  listing: IMarketplaceListing,
  detail: ILandSaleListingDetail,
  isAvailable: boolean
}

export interface ILandSaleListingDetail {
  landVoucherQuantity: number,
  discountAsPercentage: number,
  originalPrice: number,
  bonusPlotAssetSeriesGuid: string,
  bonusPlotsReward: number
}


export interface ILandSaleCashback {
  noNFTs: string,
  crownCourt: string,
  standardUser: string
}

export interface ILandSaleAssetMetadata {
  landVoucherQuantity: number,
  cashbackAmount: number,
  landSaleLevel: string,
  originalPrice: number,
  discountAsPercentage: number,
  bonusFromAssetGuid: string
}
