import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  public signInModal = new Subject<{ open: boolean, shouldRedirect: boolean }>();

  openSignInModal(shouldRedirect = true) {
    this.signInModal.next({
      open: true,
      shouldRedirect: shouldRedirect
    });
  }

  closeSignInModal() {
    this.signInModal.next({
      open: false,
      shouldRedirect: false
    });
  }
}
