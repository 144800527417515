import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UrlService } from '../../services/url.service';

@Component({
  selector: 'app-register-link',
  templateUrl: './register.component.html',
  styleUrls: ['./register-link.component.scss']
})
export class RegisterLinkComponent implements OnInit {
  @Output() submitEvent = new EventEmitter<FormGroup>();

  waitingMessage: string | undefined;
  registerGroup: FormGroup;

  formError: boolean = false;
  formSubmitted: boolean = false;
  formErrorMessage: string | undefined;
  forgotPasswordSuccess: boolean = false;

  constructor(
    private fb: FormBuilder,
    public urlService: UrlService
  ) {
      this.registerGroup = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{6,}$/)]],
        terms: [false, Validators.requiredTrue]
      });
    }

  ngOnInit(): void {

  }

  hasError() {
    this.formError = true;
  }

  register() {
    this.formError = false;
    this.formSubmitted = true;

    if (!this.registerGroup.valid) {
      return;
    }

    this.waitingMessage = 'Creating account';
    this.submitEvent.emit(this.registerGroup);
  }
}
