export enum ServiceResultStatus {
  Success = 200,
  BadRequest = 400,
  AccessDenied = 401,
  NotFound = 404,
  Timeout = 408,
  Error = 500,
  NotImplemented = 501,
  ServiceUnavailable = 503
}
