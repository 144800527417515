import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { INftCreateOfferNotification, IPurchaseNotification, NotificationResultNoResponse } from './notifications';

import {
  NotificationService,
  NotificationResult,
  ServiceResult,
  ServiceResultStatus,
  mapServiceResult,
  NotificationType
} from './';

import { INftAcceptOfferNotification } from './notifications/messages/nft-accept-offer';
import { IBlockchainWalletInstruction } from '../models/marketplace/blockchain-wallet-instruction';
import { INftAcceptOfferProgress, NftAcceptOfferProgressStatus } from '../models/marketplace/nft-accept-offer';
import { INftCancelOfferProgress, NftCancelOfferProgressStatus } from '../models/marketplace/nft-cancel-offer';
import { INftAcceptBrokeredOfferProgress, NftAcceptBrokeredOfferProgressStatus } from '../models/marketplace/nft-accept-brokered-offer';

import { IBlockchainNftCollection } from '../models/marketplace/nft-collection';
import { IAsset, IAssetName, IAssetSeries, IBasePurchaseInstruction, IBlockchainNftOfferDetails, IHederaPurchaseInstruction, IMarketplaceListing, INftCreateOfferProgress, IPurchaseProgress, IStripePurchaseInstruction, IXummPurchaseInstruction, NftCreateOfferProgressStatus, PurchaseProgressStatus } from '../models';
import { IFindCollectionNftsRequest } from '../models/xls-20-nfts/find-collection-nfts-request';
import { INftCollectionResult } from '../models/xls-20-nfts/nft-collection-result';

@Injectable({
  providedIn: 'root',
 })
 export class MarketplaceService {

  constructor(
    private notificationService: NotificationService,
    private httpClient: HttpClient) { }

  getListings() {
    return this.httpClient.get<ServiceResult<IMarketplaceListing[]>>(`${environment.apiUrl}listings?tenantGuid=${environment.tenantGuid}`);
  }

  getAssetsAttributes(assetSeriesGuid: string) {
    return this.httpClient.get<ServiceResult<IAsset>>(`${environment.apiUrl}assets/${assetSeriesGuid}/Attributes`);
  }

  getAllAssetSeries() {
    return this.httpClient.get<ServiceResult<IAssetSeries[]>>(`${environment.apiUrl}assets/series/all`);
  }

  getAllAssetsByAssetSeries(assetSeriesGuid: string) {
    return this.httpClient.get<ServiceResult<IAsset[]>>(`${environment.apiUrl}assets/allAssetsByAssetSeries?tenantGuid=${environment.tenantGuid}&AssetSeriesGuid=${assetSeriesGuid}`);
  }

  getAllAssetGuidsByAssetSeries(assetSeriesGuid: string) {
    return this.httpClient.get<ServiceResult<string[]>>(`${environment.apiUrl}assets/guids/allAssetsByAssetSeries?tenantGuid=${environment.tenantGuid}&AssetSeriesGuid=${assetSeriesGuid}`);
  }

  getAllAssetNamesByAssetSeries(assetSeriesGuid: string) {
    return this.httpClient.get<ServiceResult<IAssetName[]>>(`${environment.apiUrl}assets/names/allAssetsByAssetSeries?tenantGuid=${environment.tenantGuid}&AssetSeriesGuid=${assetSeriesGuid}`);
  }

  initializePurchase(marketplaceListing: IMarketplaceListing, quantity: number, walletProviderGuid: String, paymentInstrumentGuid: string, affiliateCode?: string) : Observable<NotificationResult<IXummPurchaseInstruction | IStripePurchaseInstruction | IHederaPurchaseInstruction | undefined, IPurchaseProgress>> {
        
    let marketplacePurchase = { 
      marketplaceListingGuid: marketplaceListing.marketplaceListingGuid, 
      walletProviderGuid: walletProviderGuid, 
      quantity: quantity, 
      paymentInstrumentGuid: paymentInstrumentGuid,
      affiliateCode: affiliateCode
    };

    return this.httpClient.post<ServiceResult<IXummPurchaseInstruction | IStripePurchaseInstruction | IHederaPurchaseInstruction | undefined>>(`${environment.apiUrl}listings/${marketplaceListing.marketplaceListingGuid}/purchase`, marketplacePurchase)
      .pipe(mapServiceResult<IXummPurchaseInstruction | IStripePurchaseInstruction | IHederaPurchaseInstruction | undefined>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IXummPurchaseInstruction | IStripePurchaseInstruction | IHederaPurchaseInstruction | undefined, IPurchaseProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PurchaseAwaitingConfirmation).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: PurchaseProgressStatus.AwaitingConfirmation,
                purchasedAssetGuids: undefined,
                progressMessage: notification.message
              });
            }
          }));

          subscriptions.push(this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PurchaseProcessing).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: PurchaseProgressStatus.Processing,
                purchasedAssetGuids: undefined,
                progressMessage: notification.message
              });
            }
          }));

          subscriptions.push(this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PurchaseFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: PurchaseProgressStatus.Failed,
                purchasedAssetGuids: undefined,
                progressMessage: notification.message
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PaymentFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: PurchaseProgressStatus.Failed,
                purchasedAssetGuids: undefined,
                progressMessage: notification.message
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PurchaseCompleted).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: PurchaseProgressStatus.Complete,
                purchasedAssetGuids: notification.purchasedAssetGuids,
                progressMessage: notification.message
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));
  }

  finaliizePurchase(transactionHash: string , ) {

  }
  finaliizeCryptoPurchase(paymentHash: string, paymentProviderGuid: string, walletProviderGuid: string, walletAccount: string , transactionGuid: string, accountGuid: string, walletResponseData: any): Observable<NotificationResultNoResponse<IPurchaseProgress>> {

    let FinalizePaymentType = 4;
    var actionData = {
      paymentHash,
      paymentProviderGuid,
      walletProviderGuid,
      walletAccount,
      walletResponseData: JSON.stringify(walletResponseData),
      transactionGuid
    };

    var data = {
      clientId: this.notificationService.getClientGuid(),
      account: accountGuid,
      actionMetadata : {
        actionData : {
          tenantGuid : environment.tenantGuid,
          actionType : FinalizePaymentType,
          data : JSON.stringify(actionData)
        }
      }
    }

    return this.httpClient.post<ServiceResult<IPurchaseNotification>>(`${environment.providerApiUrl}providers/${walletProviderGuid}/execute`, data)
      .pipe(mapServiceResult)
      .pipe(map(result => {

        let notificationResult = new NotificationResultNoResponse<IPurchaseProgress>();

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PurchaseAwaitingConfirmation).subscribe(result => {

            if (result.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: PurchaseProgressStatus.AwaitingConfirmation,
                purchasedAssetGuids: undefined,
                progressMessage: result.message
              });
            }

          })

          this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PurchaseFailed).subscribe(result => {

            if (result.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: PurchaseProgressStatus.Failed,
                purchasedAssetGuids: undefined,
                progressMessage: result.message
              });
              clearSubscriptions();
            }
            
          })

          this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PurchaseCompleted).subscribe(result => {

            if (result.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: PurchaseProgressStatus.Complete,
                purchasedAssetGuids: result.purchasedAssetGuids,
                progressMessage: result.message
              });
              clearSubscriptions();
            }
            
          })

        }


        

        return notificationResult;

      }));


  }

  claimNft(asset: IAsset) : Observable<NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptOfferProgress>> {

    let claimNft = { assetGuid: asset.assetGuid, providerGuid: environment.xummProviderGuid };

    return this.httpClient.post<ServiceResult<IBlockchainWalletInstruction>>(`${environment.apiUrl}assets/${asset.assetGuid}/claimNft`, claimNft)
      .pipe(mapServiceResult<IBlockchainWalletInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptOfferProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferAwaitingConfirmation).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: NftAcceptOfferProgressStatus.AwaitingConfirmation,
                transactionGuid: transactionGuid
              });
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptOfferProgressStatus.Failed,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferComplete).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptOfferProgressStatus.Complete,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));

  }

  acceptXls20NftOffer(offer: IBlockchainNftOfferDetails, nftTokenId : string, nftName : string, accountGuid: string, paymentInstrumentGuid : string) : Observable<NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptOfferProgress>> {

    let acceptOffer = {
      paymentInstrumentGuid : paymentInstrumentGuid,
      accountGuid : accountGuid,
      nftName : nftName,
      nftokenId : nftTokenId,
      offerId : offer.blockchainOfferLedgerId
    };

    return this.httpClient.post<ServiceResult<IBlockchainWalletInstruction>>(`${environment.apiUrl}xls-20/acceptOfferOnNft`, acceptOffer)
      .pipe(mapServiceResult<IBlockchainWalletInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptOfferProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferAwaitingConfirmation).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: NftAcceptOfferProgressStatus.AwaitingConfirmation,
                transactionGuid: transactionGuid
              });
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptOfferProgressStatus.Failed,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferComplete).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptOfferProgressStatus.Complete,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));

  }

  acceptBrokeredXls20NftOffer(offer: IBlockchainNftOfferDetails, nftTokenId : string, nftName : string, accountGuid: string, paymentInstrumentGuid : string, currentOwnerAddress: string) : Observable<NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptBrokeredOfferProgress>> {

    let acceptOffer = {
      paymentInstrumentGuid : paymentInstrumentGuid,
      accountGuid : accountGuid,
      nftName : nftName,
      nftokenId : nftTokenId,
      offerId : offer.blockchainOfferLedgerId,
      wasSellOffer: offer.isSellOffer,
      currentOwnerAddress: currentOwnerAddress
    };

    return this.httpClient.post<ServiceResult<IBlockchainWalletInstruction>>(`${environment.apiUrl}xls-20/acceptBrokeredOfferOnNft`, acceptOffer)
      .pipe(mapServiceResult<IBlockchainWalletInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptBrokeredOfferProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptBrokeredOfferAwaitingConfirmation).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: NftAcceptBrokeredOfferProgressStatus.AwaitingConfirmation,
                transactionGuid: transactionGuid
              });
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptBrokeredOfferFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptBrokeredOfferProgressStatus.Failed,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptBrokeredOfferComplete).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptBrokeredOfferProgressStatus.Complete,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));

  }

  cancelXls20NftOffer(offerId : string, nftName : string, accountGuid: string, paymentInstrumentGuid : string) : Observable<NotificationResult<IBlockchainWalletInstruction | undefined, INftCancelOfferProgress>> {

    let cancelOfferOnNftInitialization = {
      paymentInstrumentGuid : paymentInstrumentGuid,
      accountGuid : accountGuid,
      nftName : nftName,
      offerId : offerId
    };

    return this.httpClient.post<ServiceResult<IBlockchainWalletInstruction>>(`${environment.apiUrl}xls-20/cancelOfferOnNft`, cancelOfferOnNftInitialization)
      .pipe(mapServiceResult<IBlockchainWalletInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IBlockchainWalletInstruction | undefined, INftCancelOfferProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<INftCreateOfferNotification>(NotificationType.CancelOfferFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftCancelOfferProgressStatus.Failed,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftCreateOfferNotification>(NotificationType.CancelOfferComplete).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftCancelOfferProgressStatus.Complete,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));

  }

  makeXls20NftOffer(ownerAddress : string, amount: string, nftTokenId : string, nftName : string, accountGuid: string, paymentInstrumentGuid : string, currencyId : string, isSellOffer : boolean) : Observable<NotificationResult<IBlockchainWalletInstruction | undefined, INftCreateOfferProgress>> {

    let makeOfferOnNftRequest = {
      paymentInstrumentGuid : paymentInstrumentGuid,
      accountGuid : accountGuid,
      nftName : nftName,
      nftokenId : nftTokenId,
      amount : amount,
      isSellOffer : isSellOffer,
      ownerWalletAddress : ownerAddress,
      currencyId: currencyId
    };

    return this.httpClient.post<ServiceResult<IBlockchainWalletInstruction>>(`${environment.apiUrl}xls-20/makeOfferOnNft`, makeOfferOnNftRequest)
      .pipe(mapServiceResult<IBlockchainWalletInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IBlockchainWalletInstruction | undefined, INftCreateOfferProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<INftCreateOfferNotification>(NotificationType.CreateOfferFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftCreateOfferProgressStatus.Failed,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftCreateOfferNotification>(NotificationType.CreateOfferComplete).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftCreateOfferProgressStatus.Complete,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));

  }

  findXls20NftsByCollectionName(findParams: IFindCollectionNftsRequest) {
    return this.httpClient.post<ServiceResult<INftCollectionResult>>(`${environment.apiUrl}xls-20/${findParams.collectionName}/findCollectionNfts?tenantGuid=${environment.tenantGuid}`, 
    findParams,      
    );
  }

  getBlockchainNftCollections() {
    return this.httpClient.get<ServiceResult<IBlockchainNftCollection[]>>(`${environment.apiUrl}xls-20/GetAllNftCollections?tenantGuid=${environment.tenantGuid}`);
  }

}
