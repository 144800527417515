<footer [ngClass]="{'side-menu': isMenuFooter}">
  <ul>
    <li>
      <a href="https://x.com/xspectar" target="_blank"
        rel="noopener noreferrer">
        <!-- <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29 2.78903C27.9309 3.26366 26.785 3.58234 25.5804 3.72699C26.8099 2.99018 27.7547 1.82394 28.1976 0.436209C27.0495 1.11651 25.7725 1.61149 24.4187 1.87818C23.3338 0.720988 21.7879 0 20.0769 0C16.7907 0 14.1259 2.66246 14.1259 5.94872C14.1259 6.41431 14.1802 6.8686 14.2819 7.30481C9.33668 7.05619 4.95425 4.68755 2.01832 1.08713C1.50526 1.96859 1.2137 2.99018 1.2137 4.07957C1.2137 6.14309 2.26241 7.96477 3.86034 9.03382C2.88395 9.00218 1.96633 8.73322 1.16398 8.28797C1.16398 8.31283 1.16398 8.3377 1.16398 8.36256C1.16398 11.2465 3.21619 13.649 5.93516 14.1983C5.43792 14.3339 4.91131 14.4062 4.36887 14.4062C3.98465 14.4062 3.61172 14.37 3.24784 14.2977C4.00725 16.6596 6.20412 18.3841 8.80555 18.4315C6.77141 20.0272 4.20614 20.9765 1.41711 20.9765C0.935703 20.9765 0.463331 20.9493 0 20.8928C2.63082 22.5834 5.75886 23.5666 9.11971 23.5666C20.0634 23.5666 26.0482 14.5011 26.0482 6.64032C26.0482 6.38267 26.0415 6.12275 26.0324 5.86961C27.1941 5.0311 28.2022 3.98239 29 2.78903Z"
          fill="white" />
        </svg> -->
        <img height="25" width="29" src="../../../assets/images/x-icon.png"/>
      </a>
    </li>
    <li>
      <a class="social__link" href="https://discord.com/invite/xspectar" target="_blank" rel="noopener noreferrer">
        <svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.0588 0H2.9269C1.31353 0 0 1.31354 0 2.9269V22.0588C0 23.6722 1.31353 24.9857 2.9269 24.9857H19.1176L18.3609 22.3729L20.1885 24.0577L21.916 25.6425L25 28.3124V2.9269C24.9857 1.31354 23.6722 0 22.0588 0ZM16.5477 18.4894C16.5477 18.4894 16.0337 17.8755 15.6054 17.3472C17.4757 16.819 18.1896 15.6625 18.1896 15.6625C17.6042 16.048 17.0474 16.3192 16.5477 16.5049C15.8338 16.8047 15.1485 16.9903 14.4774 17.1188C13.1068 17.3758 11.8504 17.3044 10.7796 17.1045C9.96573 16.9475 9.26613 16.7333 8.68075 16.4906C8.35237 16.3621 7.99543 16.205 7.63849 16.0051C7.59566 15.9766 7.55283 15.9623 7.50999 15.9338C7.48144 15.9195 7.46716 15.9052 7.45288 15.9052C7.19589 15.7624 7.05311 15.6625 7.05311 15.6625C7.05311 15.6625 7.73843 16.7904 9.55168 17.333C9.12336 17.8755 8.59509 18.5037 8.59509 18.5037C5.43975 18.4038 4.24043 16.3478 4.24043 16.3478C4.24043 11.7933 6.2964 8.09537 6.2964 8.09537C8.35237 6.56768 10.2941 6.61051 10.2941 6.61051L10.4369 6.78184C7.86693 7.50999 6.69617 8.63792 6.69617 8.63792C6.69617 8.63792 7.01028 8.46659 7.53855 8.23815C9.06625 7.5671 10.2798 7.39577 10.7796 7.33866C10.8652 7.32439 10.9366 7.31011 11.0223 7.31011C11.8932 7.19589 12.8784 7.16733 13.9063 7.28155C15.2627 7.43861 16.719 7.83838 18.2039 8.63792C18.2039 8.63792 17.076 7.5671 14.6488 6.83895L14.8487 6.61051C14.8487 6.61051 16.8047 6.56768 18.8464 8.09537C18.8464 8.09537 20.9023 11.7933 20.9023 16.3478C20.9023 16.3335 19.703 18.3895 16.5477 18.4894Z"
            fill="white" />
        </svg>
      </a>
    </li>
    <li>
      <a class="social__link" href="https://t.me/xspectarchat" target="_blank" rel="noopener noreferrer">
        <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M22.214 23.8993C22.6031 24.1748 23.1045 24.2436 23.5516 24.0745C23.9986 23.9041 24.3273 23.5223 24.4264 23.0595C25.4764 18.1248 28.0235 5.63451 28.9793 1.14565C29.0518 0.807327 28.9309 0.455711 28.6651 0.229757C28.3993 0.00380409 28.0308 -0.0614446 27.7021 0.0605944C22.6357 1.93589 7.03282 7.79013 0.655383 10.1499C0.2506 10.2998 -0.0128106 10.6889 0.000480729 11.1154C0.0149804 11.5431 0.302557 11.9141 0.717006 12.0385C3.57707 12.894 7.33127 14.0842 7.33127 14.0842C7.33127 14.0842 9.08573 19.3826 10.0004 22.0771C10.1152 22.4155 10.3798 22.6813 10.729 22.7731C11.077 22.8637 11.4492 22.7683 11.709 22.523C13.1783 21.1359 15.4499 18.9911 15.4499 18.9911C15.4499 18.9911 19.7659 22.1557 22.214 23.8993ZM8.91053 13.4148L10.9393 20.1064L11.39 15.8689C11.39 15.8689 19.2283 8.79906 23.6966 4.76936C23.8271 4.65095 23.8452 4.45279 23.7364 4.31383C23.6289 4.17488 23.4307 4.14225 23.2821 4.2365C18.1033 7.54363 8.91053 13.4148 8.91053 13.4148Z"
            fill="white" />
        </svg>
      </a>
    </li>
    <li>
      <a class="social__link" href="https://www.instagram.com/xspectar/" target="_blank" rel="noopener noreferrer">
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.5488 0H7.45086C3.34246 0 0 3.34262 0 7.45102V19.549C0 23.6575 3.34246 27 7.45086 27H19.5488C23.6575 27 27 23.6574 27 19.549V7.45102C27.0002 3.34262 23.6575 0 19.5488 0ZM24.6046 19.549C24.6046 22.3366 22.3366 24.6044 19.549 24.6044H7.45086C4.66338 24.6046 2.39557 22.3366 2.39557 19.549V7.45102C2.39557 4.66354 4.66338 2.39557 7.45086 2.39557H19.5488C22.3365 2.39557 24.6044 4.66354 24.6044 7.45102V19.549H24.6046Z"
            fill="white" />
          <path
            d="M13.5002 6.54297C9.66392 6.54297 6.54297 9.66392 6.54297 13.5002C6.54297 17.3363 9.66392 20.4571 13.5002 20.4571C17.3364 20.4571 20.4574 17.3363 20.4574 13.5002C20.4574 9.66392 17.3364 6.54297 13.5002 6.54297ZM13.5002 18.0614C10.985 18.0614 8.93854 16.0152 8.93854 13.5C8.93854 10.9847 10.9848 8.93838 13.5002 8.93838C16.0155 8.93838 18.0618 10.9847 18.0618 13.5C18.0618 16.0152 16.0154 18.0614 13.5002 18.0614Z"
            fill="white" />
          <path
            d="M20.7528 4.51172C20.2913 4.51172 19.8379 4.69857 19.5119 5.02597C19.1844 5.35177 18.9961 5.80533 18.9961 6.26847C18.9961 6.73018 19.1845 7.18358 19.5119 7.51097C19.8377 7.83677 20.2913 8.02522 20.7528 8.02522C21.216 8.02522 21.668 7.83677 21.9953 7.51097C22.3227 7.18358 22.5096 6.73002 22.5096 6.26847C22.5096 5.80533 22.3227 5.35177 21.9953 5.02597C21.6696 4.69857 21.216 4.51172 20.7528 4.51172Z"
            fill="white" />
        </svg>
      </a>
    </li>
  </ul>
  <div *ngIf="!isMenuFooter">
    <span>© 2023 xSPECTAR. All rights reserved</span> |
    <span><a href="{{environment.marketingSite}}terms-and-conditions.html">Terms and conditions</a></span> |
    <span><a href="{{environment.marketingSite}}privacy-policy.html">Privacy Policy</a></span>
  </div>
</footer>
