export enum NftCancelOfferProgressStatus {
    Failed,
    Complete,
    Processing
}

export interface INftCancelOfferProgress {
    progressStatus: NftCancelOfferProgressStatus,
    transactionGuid: string
}
  