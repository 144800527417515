import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, tap, map } from 'rxjs';
import { IAccountTransaction, IAccountTransactionData, IPaymentInstrument } from '../../account/models';
import { environment } from 'src/environments/environment';
import { ICreateXUserRequest } from '../models/ICreateXUserRequest';
import { ILoginXUserRequest } from '../models/ILoginXUserRequest';
import { IForgottonPasswordXUserRequest } from '../models/IForgottonPasswordXUserRequest';
import { IForgotPasswordXUserResponse } from '../models/IForgotPasswordXUserResponse';
import { IResetPasswordXUserRequest } from '../models/IResetPasswordXUserRequest';
import { IAccountDetails, IAsset, ICurrencyAmount, IVaultAssetMetadata } from '../models';
import { NotificationService } from './notification.service';
import { NotificationType } from './notifications';
import { ServiceResult, mapServiceResult } from './results/service-result';
import { ServiceResultStatus } from './results/service-result-status';
import { SecurityService } from './security.service';
import { ISignInInstruction } from '../models/security/sign-in-instruction';
import { IFinaliseSignInResult } from '../models/account/IFinaliseSignInResult';
import { ISecurityToken } from '../models/account/ISecurityToken';

export interface IAddPaymentInstruction {
  data: string
}

@Injectable({
  providedIn: 'root',
 })
 export class AccountService {

  private accountTransactions: IAccountTransaction[] | undefined;
  private accountAssets: IAsset[] | undefined;
  private accountPaymentTypes: IPaymentInstrument[] | undefined;

  accountGuid: string = "";
  loggedIn : boolean = false;
  canBuyAgentNfts: boolean = false;
  canBuy444AgentNfts: boolean = false  ;
  agentsOwned = 0;
  amountInVault = 0;
  xrpBalance: ICurrencyAmount = { value: 0, currencyGuid: environment.xrpCurrencyGuid };
  xSpectarBalance: ICurrencyAmount = { value: 0, currencyGuid: environment.xSpectarCurrencyGuid };

  constructor(
    private readonly httpClient: HttpClient,
    private securityService: SecurityService,
    readonly notificationService: NotificationService) {
      notificationService.listenFor(NotificationType.SignOut).subscribe(() => {
        this.accountAssets?.splice(0);
        this.accountTransactions?.splice(0);
        this.canBuyAgentNfts = false;
        this.canBuy444AgentNfts = false;
        this.agentsOwned = 0;
        this.amountInVault = 0;
        this.loggedIn = false;
      });

      notificationService.listenFor(NotificationType.SignInApproved).subscribe(() => {

        this.getAccountAssets(true).subscribe(() => {
          this.loggedIn = true;
        });
      });
  }

  getAccountAssets(forceReload: boolean = false) {

    if (this.accountAssets && !forceReload) {
      return of({ status: 200, data: this.accountAssets } as ServiceResult<IAsset[]>);
    }

    return this.httpClient.get<ServiceResult<IAsset[]>>(`${environment.apiUrl}account/current/assets`)
      .pipe(mapServiceResult<IAsset[]>)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success && result.data) {

          result.data.forEach(x => {

            x.assetMetadata = JSON.parse(x.assetMetadata as any as string);

            if (x.assetSeries.assetSeriesGuid == environment.agentAssetSeriesGuid){
              (x.assetMetadata as any).agentImage = 'https://xspectarnfts.blob.core.windows.net/nfts-small/' + x.assetGuid + '.jpg';
              (x.assetMetadata as any).agentName = x.assetName;
            } else if (x.assetSeries.assetSeriesGuid == environment.the88agentAssetSeriesGuid) {
              (x.assetMetadata as any).agentImage = x.assetResources[0].assetResourceLocator;
              (x.assetMetadata as any).agentName = x.assetName;
            }

          });

          this.accountAssets = result.data;
          this.canBuyAgentNfts = result.data.some(x => x.assetSeries.assetSeriesGuid === environment.unlockAssetSeriesGuid);
          this.canBuy444AgentNfts  = result.data.some(x => x.assetSeries.assetSeriesGuid === environment.the88agentAssetSeriesGuid);
          this.agentsOwned = result.data.filter(x => x.assetSeries.assetSeriesGuid === environment.agentAssetSeriesGuid).length;
          this.amountInVault = this.calculateTotalVaultAmount(result.data);

        }
      }));
  }

  getAccountTransactions(forceReload: boolean = false) {
    
    if (!forceReload && this.accountTransactions) {
      return of({ status: 200, data: this.accountTransactions } as ServiceResult<IAccountTransaction[]>);
    }

    return this.httpClient.get<ServiceResult<IAccountTransaction[]>>(`${environment.apiUrl}account/current/transactions`)
      .pipe(mapServiceResult<IAccountTransaction[]>)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success && result.data) {
          this.accountTransactions = result.data;
        }
      }));
  }

  getAccountTransactionDetail(transaction: IAccountTransaction) {
    if (transaction.data) {
      return of({ status: 200, data: transaction.data } as ServiceResult<IAccountTransactionData[]>);
    }

    return this.httpClient.get<ServiceResult<IAccountTransaction>>(`${environment.apiUrl}account/current/transactions/${transaction.accountTransactionGuid}`)
      .pipe(mapServiceResult<IAccountTransaction>)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success && result.data) {
          transaction.data = result.data.data;
        }
      }))
      .pipe(map(x => ({ status: 200, data: x.data!.data } as ServiceResult<IAccountTransactionData[]>)));
  }

  getAccountPaymentInstruments(forceReload : boolean = false) {

    if (this.accountPaymentTypes && !forceReload) {
      return of({ status: 200, data: this.accountPaymentTypes } as ServiceResult<IPaymentInstrument[]>);
    }

    return this.httpClient.get<ServiceResult<IPaymentInstrument[]>>(`${environment.apiUrl}payment/paymentInstruments/current`)
      .pipe(mapServiceResult<IPaymentInstrument[]>)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success && result.data) {
           result.data.forEach(e => this.accountPaymentTypes?.push(e));
      }}));

  }


  hasPaymentInstrument(accountDetails: IAccountDetails, paymentInstrumentProviderGui: string) : boolean {
    if (accountDetails.paymentInstrumentDetails == undefined)
    {
      return false;
    }

    var result = accountDetails
      .paymentInstrumentDetails.filter(e => e.paymentInstrumentProviderGuid == paymentInstrumentProviderGui)
      .length > 0 ?? false;

    return result;

  }

  register(createXUserRequest: ICreateXUserRequest) {
    return this.httpClient.post<ServiceResult<ISecurityToken>>(`${environment.apiUrl}account/register`, createXUserRequest)
      .pipe(mapServiceResult<ISecurityToken>)
      .pipe(tap((result: ServiceResult<ISecurityToken>) => {
        if (result.status === ServiceResultStatus.Success) {
          this.securityService.setToken(result.data?.accessToken!);
          this.securityService.getAccountDetails(true).subscribe();
        }
      }));
  }

  login(loginXUserRequest: ILoginXUserRequest) {
    return this.httpClient.post<ServiceResult<IFinaliseSignInResult>>(`${environment.apiUrl}security/finalise-sign-in`, loginXUserRequest)
      .pipe(mapServiceResult<IFinaliseSignInResult>)
      .pipe(tap((result: ServiceResult<IFinaliseSignInResult>) => {
        if (result.status === ServiceResultStatus.Success) {
          this.securityService.setToken(result.data?.token.accessToken!);
          this.securityService.getAccountDetails(true).subscribe();
        }
      }));
  }

  forgotPassword(forgottonPasswordXUserRequest: IForgottonPasswordXUserRequest) {
    return this.httpClient.post<ServiceResult<IForgotPasswordXUserResponse>>(`${environment.apiUrl}account/forgotPassword`, forgottonPasswordXUserRequest)
      .pipe(mapServiceResult<any>);
  }

  checkResetPassword(guid: any) {
    return this.httpClient.get<ServiceResult<boolean>>(`${environment.apiUrl}account/${guid}/reset`)
    .pipe(mapServiceResult<boolean>);
  }

  resetPassword(guid: any, resetPasswordXUserRequest: IResetPasswordXUserRequest) {
    return this.httpClient.post<ServiceResult<boolean>>(`${environment.apiUrl}account/${guid}/reset`, resetPasswordXUserRequest)
      .pipe(mapServiceResult<boolean>);
  }

  addXuserToAccount(createXUserRequest: ICreateXUserRequest) {
    return this.httpClient.post<ServiceResult<any>>(`${environment.apiUrl}account/addXuser`, createXUserRequest)
      .pipe(mapServiceResult<any>);
  }

  updatePrimaryPaymentInstrument(paymentInstrument: IPaymentInstrument) {
    return this.httpClient.post<ServiceResult<any>>(`${environment.apiUrl}account/paymentInstruments/MakePrimary`, paymentInstrument)
      .pipe(mapServiceResult<any>);
  }

  removeCrytoWallet(securityGuid: string) {

    return this.httpClient.get<ServiceResult<any>>(`${environment.apiUrl}account/current/RemoveCryptoWallet/${securityGuid}`)
      .pipe(mapServiceResult<any>);
    

  }

  InitializeAddCryptoWallet(clientGuid: string, walletProviderGuid: string, securityIssuerProviderGuid: string) {
    return this.httpClient.post<ServiceResult<ISignInInstruction>>(`${environment.apiUrl}account/InitializeAddCryptoWallet`, {
        clientGuid,
        walletProviderGuid,
        securityIssuerProviderGuid
      })
      .pipe(mapServiceResult<ISignInInstruction>);
  }

  private calculateTotalVaultAmount(assets: IAsset[]) {
    let vaultAmount = 0;
    assets.filter(x => x.assetSeries.assetCollection?.assetCollectionGuid === environment.vaultAssetCollectionGuid).forEach(x => {

      if (x.assetTypeGuid.toLocaleLowerCase() == environment.tokenStakeAssetTypeGuid && x.assetStatusGuid == environment.activeAssetStatusGuid){
        vaultAmount += (x.assetMetadata as IVaultAssetMetadata).amount;
      }

    });
    return vaultAmount;
  }

}
