<app-waiting
  [message]="'Loading account details'"
  [isWaiting]="isLoading"
  [fullScreen]="false"
  *ngIf="isLoading">
</app-waiting>

<ng-container *ngIf="!isLoading">
  <div>
    <form class="account-form" *ngIf="detailsForm" [formGroup]="detailsForm" (ngSubmit)="update()">
      <input type="hidden" id="account-guid" formControlName="accountGuid"/>
      <div>
        <div class="account-form__group">
          <label for="user-name">
            Account Id
          </label>
          <div class="form-input-group">
            <span>{{getAccountId()}}</span>
          </div>
        </div>
        <div class="account-form__group">
          <label for="user-name">
            Username
          </label>
          <div class="form-input-group">
            <input type="text" id="user-name" formControlName="username" autocomplete="off"
              spellcheck="false" placeholder="Your Username"  />

          </div>
        </div>
        <div class="account-form__group">
          <label for="user-email">
            E-mail
          </label>
          <div class="form-input-group">
            <input type="email" id="user-email" formControlName="emailAddress" autocomplete="off"
              spellcheck="false" placeholder="Your Email"  />
          </div>
        </div>

        <div class="account-form__group">
          <label for="preference-marketing">
            Email notification
          </label>
          <div class="form-input-group form-input-group--checkbox">
            <input type="checkbox" role="switch" formControlName="optInForMarketingEmails" name="preference-marketing" id="preference-marketing">
            <small>Yes, please send me marketing updates..?</small>
          </div>       
        </div>

        

        <div formArrayName="preferences">
          <div *ngFor="let pref of preferencesArray.controls; let i = index" [formGroupName]="i" class="account-form__group">
            <label for="preference-{{i}}">
              {{ pref.value.emailTypeName }}
            </label>
            <div class="form-input-group form-input-group--checkbox">
              <input type="checkbox" role="switch" formControlName="isEnabled" name="preference-{{i}}" id="preference-{{i}}">
              <small>{{ pref.value.emailDescription }}</small>
            </div>
          </div>
        </div>
      </div>

      <button type="button" class="button button--secondary" [disabled]="!detailsForm.dirty" (click)="update()">Save</button>
    </form>
  </div>
</ng-container>


