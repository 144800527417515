import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountService } from '../../../shared/services/account.service';
import { IVaultAssetMetadata } from 'src/app/v2/shared/models';
import { ServiceResultStatus } from 'src/app/v2/shared/services';

@Component({
  selector: 'app-xs-lock-vault',
  templateUrl: './xs-lock-vault.component.html',
  styleUrls: ['./xs-lock-vault.component.scss']
})
export class XsLockVaultComponent implements OnInit {
  displayedVaultAssets: IVaultAssetMetadata[] = [];
  isLoading = true;
  environment: any;

  constructor(private readonly accountService: AccountService) {
    this.environment = environment;
  }

  ngOnInit(): void {
    this.accountService
      .getAccountAssets()
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(result => {
        if (result.status === ServiceResultStatus.Success && result.data) {
          this.displayedVaultAssets = result.data
          .filter(x => x.assetSeries.assetCollection?.assetCollectionGuid === environment.vaultAssetCollectionGuid && x.assetStatusGuid == environment.activeAssetStatusGuid)
          .map(x => x.assetMetadata as IVaultAssetMetadata);

        }
      });
  }

}
