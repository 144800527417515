<div>
  <h3 class="header" >Please note, when you remove a crypto wallet from your account you will no longer be able to use it for payments.</h3>
  
  <div  class="provider">
    <h2>Xrpl</h2>
    <hr>

    <div *ngIf="xrplPaymentInstruments && xrplPaymentInstruments!.length > 0">

      <h3>Xrpl wallets</h3>

      <ul>
        <li *ngFor="let xummWallet of xrplPaymentInstruments" class="walletAccount">
          <span>{{xummWallet.externalReference}}</span> <button class="button button--secondary" [disabled]="xummWallet.isPrimary" (click)="makePrimary(xummWallet, xrplPaymentInstruments!)">Make Primary</button>
        </li>
      </ul>

    </div>
  
  </div>

  <div class="provider">

    <h2>Hedera</h2>
    <hr>

    <div *ngIf="hederaPaymentInstruments && hederaPaymentInstruments.length > 0">
      <h3>Hedera wallets</h3>

      <ul>
        <li *ngFor="let hederaWallet of hederaPaymentInstruments" class="walletAccount">
          <span>{{hederaWallet.externalReference}}</span> <button class="button button--secondary" [disabled]="hederaWallet.isPrimary" (click)="makePrimary(hederaWallet, hederaPaymentInstruments!)">Make Primary</button>
        </li>
      </ul>

    </div>

  </div>

</div>

