import { Component, OnInit } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { IAccountTransaction } from '../../models';
import { AccountService } from '../../../shared/services/account.service';
import { environment } from 'src/environments/environment';
import { ServiceResultStatus } from 'src/app/v2/shared/services';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
  transactions?: IAccountTransaction[];
  isLoading = true;
  environment: any;
  showMobileDetails = false;

  constructor(private accountService: AccountService) {
    this.environment = environment;
  }

  ngOnInit(): void {
    this.accountService
      .getAccountTransactions(true)
      .pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(result => {
        if (result.status === ServiceResultStatus.Success) {

          this.transactions = result.data;
          this.transactions?.forEach(e => {

            if (e.accountTransactionStatusName.toLocaleLowerCase() == "generated") {
              e.accountTransactionStatusName = "Awaiting Payment"
            }

          })

        }
      });
  }

}
