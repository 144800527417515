import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-v2',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class NewFooterComponent implements OnInit {

  environment: any;

  @Input() isMenuFooter: boolean = false;

  constructor() {
    this.environment = environment;
  }

  ngOnInit(): void {
  }

}
