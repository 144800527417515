import { Injectable } from '@angular/core';
import Web3 from 'web3';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { HashConnect, HashConnectConnectionState, SessionData } from 'hashconnect';
import { environment } from 'src/environments/environment';
import { SecurityService } from './security.service';
import { NotificationType } from './notifications';
import { NotificationService } from './notification.service';
import { AccountService } from './account.service';
import { IUniSatSignedMessageResponse } from '../models/UniSatSignedMessageResponse';
import { IWalletAddedResult } from '../models/security/wallet-added-result';
import { AccountId, LedgerId, SignerSignature, TransactionResponse, TransferTransaction } from '@hashgraph/sdk';
import { Buffer } from "buffer";
import { read } from 'fs';

declare let window: any;

@Injectable({
  providedIn: 'root',
 })
export class LocalWalletsService {

  private supportedChainIds = new Map<number, string>();

  private _web3!: Web3;
  private solanaPublicKey: string | null = "";

  private _ethereumEventsSetup : boolean = false;
  private _ethereumCurrentAccount : string = ""; 
  private _ethereumWallets : any = [];
  private _ethereumConnectedChainId : number = 0;

  activeEthereumAccount : BehaviorSubject<string> = new BehaviorSubject("");
  activeXdcAccount : BehaviorSubject<string> = new BehaviorSubject("");
  activeEthereumChain : BehaviorSubject<{chainId: number, name: string}> = new BehaviorSubject({chainId:0, name:""});
  activeBtcAccount : BehaviorSubject<string> = new BehaviorSubject("");

  private _ethereumConnectedChainIdSuppoerted : boolean = false;
  private _ethereumConnected : boolean = false;


  activeSolanaAccount : BehaviorSubject<string> = new BehaviorSubject("");

  

  generalEvmWalletStatus : BehaviorSubject<{status: string, chainId: number, name: string}> = new BehaviorSubject({status: 'unknown', chainId:0, name:""});

  hederaWalletAdded : BehaviorSubject<IWalletAddedResult | object> = new BehaviorSubject({});
  xrplWalletAdded : BehaviorSubject<IWalletAddedResult | object> = new BehaviorSubject({});
  xdcWalletAdded : BehaviorSubject<IWalletAddedResult | object> = new BehaviorSubject({});
  ethereumWalletAdded : BehaviorSubject<IWalletAddedResult | object> = new BehaviorSubject({});
  btcWalletAdded : BehaviorSubject<IWalletAddedResult | object> = new BehaviorSubject({});
  flareWalletAdded : BehaviorSubject<IWalletAddedResult | object> = new BehaviorSubject({});
  songbirdWalletAdded : BehaviorSubject<IWalletAddedResult | object> = new BehaviorSubject({});
  bscWalletAdded : BehaviorSubject<IWalletAddedResult | object> = new BehaviorSubject({});

  btcPluginsFound : Subject<{ satsConnect: boolean, uniSats : boolean }> = new Subject();

  _uniSatsEventsSetup : boolean = false;
  activeUniSatsAccount : Subject<string> = new Subject();
  activeUniSatsNetwork : Subject<string> = new Subject();

  private _activeSubscriptions : Subscription[] = [];

  constructor(private _securityService : SecurityService, private notificationService: NotificationService, private accountService: AccountService) {

    this.supportedChainIds.set(43114, "Avalanche Network C-Chain");
    this.supportedChainIds.set(137, "Polygon");
    this.supportedChainIds.set(1, "Etherium");
    this.supportedChainIds.set(5, "Goerli");
    this.supportedChainIds.set(11155111, "Sepolia");
    this.supportedChainIds.set(51, "XDC Apothem")
    this.supportedChainIds.set(50, "XDC")

    this.supportedChainIds.set(14, "Flare")
    this.supportedChainIds.set(19, "SongBird")

    this.supportedChainIds.set(16, "Coston")
    this.supportedChainIds.set(114, "Coston2")

    this.supportedChainIds.set(56, "Binance Smart Chain Mainnet")
    this.supportedChainIds.set(97, "Binance Smart Chain Testnet")

    this.setupNotiforcationhandlers();

  }

  ngOnDestroy(): void {

    if (this._activeSubscriptions && this._activeSubscriptions.length > 0){
      this._activeSubscriptions.forEach(e =>  e.unsubscribe());
    }
  }
  
  // Generic methods
  async signAuthData(data: string, walletProviderId: string) : Promise<string | SignerSignature[] | IUniSatSignedMessageResponse | undefined>  {

    var response: string | SignerSignature[] | IUniSatSignedMessageResponse | undefined;

    if (walletProviderId == environment.genericEvmWalletProviderGuid) {
      response =  await this.signAuthDataWithEthereum(data);
    } else if (walletProviderId == environment.hashPackProviderGuid) {
      response = await this.signAuthDataWithHashPack(data);
    } else if (walletProviderId == environment.uniSatProviderGuid){
      response =  await this.signAuthDataWithUniSats(data);
    } 
// Not used
    else if (walletProviderId == environment.solanaProviderGuid) {
      response =  JSON.stringify(await this.signAuthDataWithSolana(data));
    }
    
    return response;

  }
  private setupNotiforcationhandlers() {

    let subscribe = <NotificationMessage>(notificationType: NotificationType, handler: (message: any | undefined) => void) => {
      this._activeSubscriptions.push(this.notificationService.listenFor<NotificationMessage>(notificationType).subscribe(handler.bind(this)));
    };

    subscribe(NotificationType.SignInInitialized, async signInInstruction => {

      let walletProviderId = "";
      let securityIssuerProviderGuid  ="";
      let accountId = "";

      walletProviderId = signInInstruction.data.signInProviderGuid;
      securityIssuerProviderGuid = signInInstruction.data.securityIssuerProviderGuid;

      if (signInInstruction.securityIssuerProviderGuid == environment.ethereumProviderGuid){
        accountId = this._ethereumCurrentAccount;       
      } else if(signInInstruction.securityIssuerProviderGuid == environment.flareProviderGuid){
        accountId = this._ethereumCurrentAccount;
      }  else if(signInInstruction.securityIssuerProviderGuid == environment.songbirdProviderGuid){
        accountId = this._ethereumCurrentAccount;
      } else if(signInInstruction.securityIssuerProviderGuid == environment.bscProviderGuid){
        accountId = this._ethereumCurrentAccount;
      }
      
      else if(signInInstruction.securityIssuerProviderGuid == environment.xdcProviderGuid){
        accountId = this._ethereumCurrentAccount;
      } else if (signInInstruction.securityIssuerProviderGuid == environment.hederaProviderGuid){
        accountId = this._hashConnectPairingData?.accountIds[0]!;
        walletProviderId = environment.hashPackProviderGuid;
      }  else if (signInInstruction.securityIssuerProviderGuid == environment.btcProviderGuid){
        walletProviderId = environment.uniSatProviderGuid;
        securityIssuerProviderGuid = environment.btcProviderGuid;
      }
      
      // not used yet
      else if (signInInstruction.securityIssuerProviderGuid == environment.solanaProviderGuid){
        walletProviderId = environment.solanaProviderGuid;
        securityIssuerProviderGuid = environment.solanaProviderGuid;
      }

      if (walletProviderId) {
        var response = await this.signAuthData(signInInstruction.data.data, walletProviderId);

        if (!response) {
          return;
        }

        var publicKey = '';
        var sig = response;
        var hashpackSig : Uint8Array | undefined;

        if (walletProviderId == environment.hashPackProviderGuid) {
          publicKey = (response as SignerSignature[])[0].publicKey.toStringDer()!;
          hashpackSig = (response as SignerSignature[])[0].signature;
        } else if (walletProviderId == environment.uniSatProviderGuid) {
          publicKey = (response as IUniSatSignedMessageResponse).publicKey!;
          sig = (response as IUniSatSignedMessageResponse).signature;
        }

        this._securityService.finaliseWalletTokenLogin(walletProviderId, securityIssuerProviderGuid, signInInstruction.data.inboundActionData.actionType, accountId, signInInstruction.data.data, hashpackSig ?? sig, publicKey);
      
      }

    });

    subscribe(NotificationType.AddCryptoWalletComplete, result => {

      if (result.securityIssuerProviderGuid == environment.hederaProviderGuid) {
        this.hederaWalletAdded.next(result);
      } else if (result.securityIssuerProviderGuid == environment.xrplBlockchainProviderGuid) {
        this.xrplWalletAdded.next(result);
      } else if(result.securityIssuerProviderGuid  == environment.ethereumProviderGuid){
        this.ethereumWalletAdded.next(result);
      } else if(result.securityIssuerProviderGuid == environment.xdcProviderGuid){
        this.xdcWalletAdded.next(result)
      } else if(result.securityIssuerProviderGuid == environment.btcProviderGuid){
        this.btcWalletAdded.next(result)
      } else if(result.securityIssuerProviderGuid == environment.flareProviderGuid){
        this.flareWalletAdded.next(result)
      } else if(result.securityIssuerProviderGuid == environment.songbirdProviderGuid){
        this.songbirdWalletAdded.next(result)
      } else if(result.securityIssuerProviderGuid == environment.bscProviderGuid){
        this.bscWalletAdded.next(result)
      }

    });
    subscribe(NotificationType.AddCryptoWalletFailed, async result => {

    });
    subscribe(NotificationType.AddCryptoWalletComplete, async result => {

    });

  }

  // Etherium methods
  async signInWithEtherium(cryptoPrividerName: string): Promise<void> {

    let providerGuid:string = "";
    let walletProviderGuid: string = environment.genericEvmWalletProviderGuid;

    if(cryptoPrividerName === 'Ethereum'){
        providerGuid = environment.ethereumProviderGuid
    } else if (cryptoPrividerName === 'Flare'){
      providerGuid = environment.flareProviderGuid
    } else if (cryptoPrividerName === 'Songbird'){
      providerGuid = environment.songbirdProviderGuid
    } else if (cryptoPrividerName === 'Xdc'){
      providerGuid = environment.xdcProviderGuid;
    } else if (cryptoPrividerName === 'Hedera'){
      providerGuid = environment.hederaProviderGuid;
    } else if (cryptoPrividerName === 'BSC'){
      providerGuid = environment.bscProviderGuid;
    }
    

    this._securityService.signIn(walletProviderGuid, providerGuid);
    
  }
  async signAuthDataWithEthereum(authData: string) : Promise<string> {

    var params = [authData, this._ethereumCurrentAccount];
    var method = 'personal_sign';

    const signature = await window.ethereum.request({ method, params });
    return signature;

  }
  async connectEthereumWallet(requestAccount: boolean = true) {

    if (window.ethereum) {
      
        this.generalEvmWalletStatus.next({status: 'Enabled', chainId: 0, name: ""});

        this.setupEtheriumEvents();

        if (!requestAccount) {
          return
        }

        await window.ethereum.enable();
        this._web3 = new Web3(window.ethereum);
        this._ethereumWallets = await window.ethereum.request({method: 'eth_requestAccounts'});

        this._ethereumConnectedChainId = await this._web3.eth.getChainId();

        this.checkEtheriumChainId(this._ethereumConnectedChainId);
        this.checkEtheriumConnected(this._ethereumWallets);
        
    } else {
      console.log('Non-Ethereum browser detected. Please install MetaMask!');
    }

  }
  async addEthereumWallet( cryptoPrividerName: string, walletProviderGuid: string) {

    let securityIssuerProviderGuid: string;
    let accountId = this.activeEthereumAccount.value!

    switch ( cryptoPrividerName ) {
      case 'Ethereum':
          securityIssuerProviderGuid = environment.ethereumProviderGuid
          break;
      case 'Flare':
        securityIssuerProviderGuid = environment.flareProviderGuid
        break;
      case 'Songbird':
        securityIssuerProviderGuid = environment.songbirdProviderGuid
        break;
      case 'Hedera' :
        securityIssuerProviderGuid = environment.hederaProviderGuid
        accountId = this._hashConnectPairingData?.accountIds[0]!;
        break;
      case 'BSC':
        securityIssuerProviderGuid = environment.bscProviderGuid
        break;
      default: 
          securityIssuerProviderGuid = environment.xdcProviderGuid
          break;
   }
    
    this.accountService.InitializeAddCryptoWallet(this._securityService.getClientGuid(), environment.genericEvmWalletProviderGuid, securityIssuerProviderGuid).subscribe(async (InitializeAddCryptoWalletResponse: any) => {

      let responseData = InitializeAddCryptoWalletResponse.data.data;
      let walletProviderId = environment.genericEvmWalletProviderGuid;

      let responseSign = await this.signAuthData(responseData.data, walletProviderGuid);
      if (!responseSign) {
        return;
      }

      var publicKey = '';
        var sig = responseSign;
        var hashpackSig : Uint8Array | undefined;

        if (walletProviderGuid == environment.hashPackProviderGuid) {
          publicKey = (responseSign as SignerSignature[])[0].publicKey.toStringDer()!;
          hashpackSig = (responseSign as SignerSignature[])[0].signature;
          walletProviderId = environment.hashPackProviderGuid;
        } else if (walletProviderGuid == environment.uniSatProviderGuid) {
          publicKey = (responseSign as IUniSatSignedMessageResponse).publicKey!;
          sig = (responseSign as IUniSatSignedMessageResponse).signature;
        }

      this._securityService.finaliseWalletTokenLogin(walletProviderId, securityIssuerProviderGuid, responseData.inboundActionData.actionType, accountId, responseData.data, hashpackSig ?? sig, publicKey);

    });

  }
  private setupEtheriumEvents() : void {

    if (!this._ethereumEventsSetup) {

      let self = this;

      window.ethereum.on('accountsChanged', function (accounts : any) {
        self.checkEtheriumConnected(accounts);
        console.log(accounts);
      });

      window.ethereum.on('chainChanged', function (networkId : any) {

        // this is a hex value
        let asInt =  parseInt(networkId, 16)
        self.checkEtheriumChainId(asInt);
        console.log(networkId);
      });

      window.ethereum.on('connect', function (networkId : any) {
        self.generalEvmWalletStatus.next({status: 'connected', chainId: networkId, name: self.supportedChainIds.get(networkId) ?? ""});
        console.log(networkId);
      });

      window.ethereum.on('disconnect', function (networkId : any) {
        self._ethereumWallets = [];
        self._ethereumConnectedChainId = 0;
        console.log("Web3 Diconnected");
      });

      this._ethereumEventsSetup = true;
    }


  }
  private checkEtheriumChainId(chainId: number) : boolean {

    this._ethereumConnectedChainIdSuppoerted = this.supportedChainIds.has(chainId);
    this.activeEthereumChain.next({chainId: chainId, name: this.supportedChainIds.get(chainId) ?? ""});

    return this._ethereumConnectedChainIdSuppoerted;

  }
  private checkEtheriumConnected(accounts : any[]) : boolean {

    this._ethereumCurrentAccount = accounts[0];
    this.activeEthereumAccount.next(accounts[0]);
    return this._ethereumConnected;

  }
  async requestEvmPayment(value: string, destinationWallet: string, chainId: number): Promise<string>{

    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

    if (parseInt(window.ethereum.chainId) != chainId) {
      return "Incorrect chain selected for payment";
    }

    const result = await window.ethereum     
    .request({
      method: 'eth_sendTransaction',

      params: [
        {
          from: accounts[0], 
          to: destinationWallet,
          value: value,
        },        
      ]
    })

    return result;    
  }

  // Solana methods
  async signAuthDataWithSolana(authData: string) : Promise<string> {
    return this.signDataWithSolana(authData);
  }
  connectSolanaWallet() {

    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {

        provider.connect()
          .then(async () => {
            this.solanaPublicKey = provider.publicKey;
            this.solanaConnected(this.solanaPublicKey!);
            let result = await this.signDataWithSolana("Sup brarr");
          })
          .catch((e : any) => {
            console.warn(e);
          });

        provider.on('accountChanged', (publicKey : any) => {
          if (publicKey) {
            this.solanaConnected(publicKey.toBase58());
          } else {
            // Attempt to reconnect to Phantom
            provider.connect().catch((error : any) => {
              console.log(error);
            });
          }
        });
      }
    } else {
      console.log('Phantom wallet is not installed.');
    }
  }
  async signInWithSolana() {
    this._securityService.signIn(environment.genericEvmWalletProviderGuid, environment.solanaProviderGuid);
  }
  async disconnectSolana() {
    await window.solana.disconnect();
  }
  private solanaConnected(publicKey: string) {
    this.activeSolanaAccount.next(`${publicKey}`);
  
  } 
  async signDataWithSolana(dataToShow: string) : Promise<string> {

    const encodedMessage = new TextEncoder().encode(dataToShow);
    const signedMessage = await window.solana.signMessage(encodedMessage, "utf8");

    return signedMessage;
    
  }
  

  // Hedera/HackPack wallets
  hashConnectAccount : Subject<string> = new Subject()
  hashConnectWalletStatus : Subject<HashConnectConnectionState> = new Subject();

  private _hashConnectPairingData : SessionData | undefined;

  _hashConnectClient: HashConnect | undefined;
  _hashConnectState: HashConnectConnectionState = HashConnectConnectionState.Disconnected;

  async connectHashConnectWallet() {

    var network = LedgerId.TESTNET;
    var debug = true;
    if (environment.hashpackNetwork == "live") {
      network = LedgerId.MAINNET;
      debug = false;
    }

    window.Buffer = window.Buffer || Buffer;

    this._hashConnectClient = new HashConnect(network, environment.hashpackProjectId, environment.hashpackAppMetadata, debug);

    this.setupHashConnectEvents(this._hashConnectClient);

    await this._hashConnectClient.init();

    if (!this._hashConnectClient.pairingString) {
      this._showHederaParingModal();
    }
  
  }
  private setupHashConnectEvents(client : HashConnect) {
    
    client.pairingEvent.on((newPairing) => {
      this._hashConnectPairingData = newPairing;
      // Should be only the first?
      this.hashConnectAccount.next(newPairing.accountIds[0]);
    })

    client.disconnectionEvent.on((data) => {
      this._hashConnectPairingData = undefined;
      this.hashConnectAccount.next("");
    });

    client.connectionStatusChangeEvent.on((connectionStatus) => {
        this._hashConnectState = connectionStatus;
        this.hashConnectWalletStatus.next(connectionStatus)
    })

  }
  async disconnectHashConnectPairing() {

    if (!this._hashConnectClient) {
      return;
    }

    this._hashConnectClient.disconnect()
    

  } 
  disconnectHashpackWallet() {

    if (!this._hashConnectClient) {
      return;
    }

    this._hashConnectClient.disconnect()
  }
  pairHashpackWallet() {
    
    if (!this._hashConnectClient) {
      return;
    }

    this._showHederaParingModal();

  }

  private async _showHederaParingModal() {

    if (!this._hashConnectClient) {
      return;
    }

    await this._hashConnectClient.disconnect()
    this._hashConnectClient.openPairingModal();

    setTimeout(() => {
      var hashConnectShadowDom = document.getElementsByTagName("wcm-modal")[0].shadowRoot;
      if (hashConnectShadowDom) {
        hashConnectShadowDom!.getElementById("wcm-modal")!.style.zIndex = "100000"
      }
    }, 250)
    

  }

  async requestHashPackPayment(network: string, fromAccount: string,toAccount: string, amount: number, memo: string) : Promise<TransactionResponse | string> {

    if (!this._hashConnectClient || !this._hashConnectPairingData) {
      return "No wallet connected";
    }

    var signer = this._hashConnectClient.getSigner(AccountId.fromString(fromAccount));
    var amountToSend = amount *-1;
    var amountToReceive = amount;

    var transaction = new TransferTransaction()
        .addHbarTransfer(fromAccount, amountToSend)
        .addHbarTransfer(toAccount, amountToReceive)
        .setTransactionMemo(memo)
        .freezeWithSigner(signer);

    let response = await (await transaction).executeWithSigner(signer);

    if (response)
      return response;

    return "Transaction rejected";

  }
  async signAuthDataWithHashPack(authData: string) : Promise<SignerSignature[] | string | undefined> {

    if (!this._hashConnectClient || !this._hashConnectPairingData) {
      return "No wallet connected";
    }

    let signer = this._hashConnectClient.getSigner(AccountId.fromString(this._hashConnectPairingData.accountIds[0]));
    var enc = new TextEncoder(); // always utf-8

    try {
      let signature = await signer.sign([enc.encode(authData)]);
      return signature;
    } catch (e) {
      return undefined;
    } 

  }


  // Btc methods

  async signInWithBitCoin(walletProviderGuid: string) {
    this._securityService.signIn(walletProviderGuid, environment.hederaProviderGuid);
  }

  checkForBtcWallets() {

    var satsConnect = window.BitcoinProvider != undefined;
    var uniSats = window.unisat != undefined;

    this.btcPluginsFound.next({ satsConnect, uniSats });

  }
  async connectUniSatsWallet(requestAccount: boolean = true) {

    if (window.unisat) {
      
        this.setupUniSatsvents();

        try {
          let accounts = await window.unisat.requestAccounts();
          this.activeUniSatsAccount.next(accounts[0]);
          this.activeBtcAccount.next(accounts[0]);
        } catch (e) {
          console.log('connect failed');
        }
        
    } else {
      console.log('UniSat missing. Please install UniSat!');
    }

  }
  async addBtcWallet(walletManagerProviderGuid:string) {

    this.accountService.InitializeAddCryptoWallet(this._securityService.getClientGuid(), environment.genericBtcWalletProviderGuid, environment.btcProviderGuid).subscribe(async (response: any) => {

      let responseData = response.data.data;

      var sig = '';
      let publicKey = '';

      if (walletManagerProviderGuid == environment.uniSatProviderGuid) {
        let signedData = await this.signAuthDataWithUniSats(responseData.data);
        sig = signedData.signature;
        publicKey = signedData.publicKey ?? '';
      }

      this._securityService.finaliseWalletTokenLogin(environment.genericBtcWalletProviderGuid, environment.btcProviderGuid, responseData.inboundActionData.actionType, this.activeBtcAccount.value, responseData.data, sig, publicKey);

    });

  }

  getBufferLength(n: number) {

      var buf = undefined;
      if (n < 253) {
        buf = Buffer.alloc(1);
        buf.writeUInt8(n, 0);
      } else if (n < 0x10000) {
        buf = Buffer.alloc(1 + 2);
        buf.writeUInt8(253, 0);
        buf.writeUInt16LE(n, 1);
      } else if (n < 0x100000000) {
        buf = Buffer.alloc(1 + 4);
        buf.writeUInt8(254, 0);
        buf.writeUInt32LE(n, 1);
      } else {
        buf = Buffer.alloc(1 + 8);
        buf.writeUInt8(255, 0);
        buf.writeInt32LE(n & -1, 1);
        buf.writeUInt32LE(Math.floor(n / 0x100000000), 5);
      }
      return buf;

  }

  async signAuthDataWithUniSats(authData: string) : Promise<IUniSatSignedMessageResponse> {

    const signature = await window.unisat.signMessage(authData);

    var pubKey = await window.unisat.getPublicKey();

    return { signature : signature, publicKey : pubKey};
  }

  private setupUniSatsvents() : void {

    if (!this._uniSatsEventsSetup) {

      let self = this;

      window.unisat.on('accountsChanged', (accounts: Array<string>) => {

        self.activeUniSatsAccount.next(accounts[0]);
        self.activeBtcAccount.next(accounts[0]);

      });

      window.unisat.on('networkChanged', (network: string) => {
        self.activeUniSatsNetwork.next(network);
      });

      this._uniSatsEventsSetup = true;
    }


  }
  

}
