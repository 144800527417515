<app-waiting
  [message]="'Loading land'"
  [isWaiting]="isLoading"
  [fullScreen]="false"
  *ngIf="isLoading">
</app-waiting>

<ng-container *ngIf="!isLoading">

  <div>
    <h1>Land Plots</h1>
  </div>

  <div *ngIf="!noLand">
    <div class="total-plots">

      <div>
        <span>Total plots purchased: </span><span>{{totalPlots}}</span>
      </div>

      <div>
        <span>Total redeemed : </span><span>{{xSpectarAccount!.totalPlotsClaimed}}</span>
      </div>

      <div>
        <span>Reserved plots: </span><span>{{xSpectarAccount?.totalPlotsUsed}}</span>
      </div>

      <hr>
    </div>

    <div class="land">

      <div *ngIf="finalLandPlotAssets.length > 0" class="land__voucher land__voucher--fl-mint">

        <table>

          <col width="5%">
          <col width="19%">
          <col width="19%">
          <col width="19%">
          <col width="19%">
          <col width="19%">

          <thead>
              <th>Type</th>
              <th>Plots</th>
              <th>Date Received</th>
              <th>Status</th>
          </thead>
          <tbody>

              <tr *ngIf="landSaleAssets.length > 0">
                <td colspan="4">
                  <h3>Vouchers</h3>
                </td>
              </tr>
              <tr *ngFor="let voucher of landSaleAssets; index as i" class="allocations">
                  <td>{{voucher.assetName}}</td>
                  <td>{{getVoucherPlotTotal(voucher)}}</td>
                  <td>{{toLocalDate(voucher.created)}}</td>
                  <td>{{voucherStatus(voucher)}}</td>
              </tr>

              <tr *ngIf="finalLandPlotAssets.length > 0">
                <td colspan="4">
                  <h3>Single Plots</h3>
                </td>
              </tr>
              <tr *ngFor="let voucher of finalLandPlotAssets; index as i" class="allocations">
                <td>{{voucher.assetName}}</td>
                <td>1</td>
                <td>{{toLocalDate(voucher.created)}}</td>
                <td>{{voucherStatus(voucher)}}</td>
              </tr>

              <tr *ngIf="freePreMintLandSaleAssets.length > 0">
                <td colspan="4">
                  <h3>Free Plots</h3>
                </td>
              </tr>
              <tr *ngFor="let voucher of freePreMintLandSaleAssets; index as i" class="allocations">
                <td>{{voucher.assetName}}</td>
                <td>{{getFreePlotTotal(voucher)}}</td>
                <td>{{toLocalDate(voucher.created)}}</td>
                <td>{{voucherStatus(voucher)}}</td>
              </tr>

          </tbody>
      </table>  
        

      </div>
    </div>
  </div>

  <div *ngIf="noLand">
    <p class="empty-state-para">We're sorry! You dont have any land yet in the xSPECTARVERSE. Click the link below to get your land now!</p>

      <a class="button button--secondary" routerLink="/buy-land">Buy land</a>
  </div>
</ng-container>
