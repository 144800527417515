<div class="waiting-background" *ngIf="isWaiting" [ngClass]="{ 'waiting': isWaiting, 'full-screen' : fullScreen }">
  <div>
    <svg
      id="spinner"
      width="300px"
      height="200px"
      viewBox="0 0 187.3 93.7"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        id="outline-bg"
        fill="none"
        stroke="rgba(0, 0, 0, 0.5)"
        stroke-width="4"
        stroke-line-cap="round"
        stroke-line-join="round"
        stroke-miter-limit="10"
        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 				c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
      />
      <path
        stroke="#FFF"
        id="outline"
        fill="none"
        stroke-width="4"
        stroke-line-cap="round"
        stroke-line-join="round"
        stroke-miter-limit="10"
        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 				c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
      />
    </svg>
    <span class="text-center">{{ message }}</span>
  </div>
</div>
