import { IBlockchainLedgerWalletDetails } from "./blockchain-ledger-wallet-details";

export interface IBlockchainNftOfferDetails {

    blockchainOfferLedgerId: string;
    isSellOffer: boolean;
    amount: number;
    currency: string
    currencyIssuerWalletDetails: IBlockchainLedgerWalletDetails | undefined;
    destinationWalletWalletDetails: IBlockchainLedgerWalletDetails | undefined;
    sourceWalletWalletDetails: IBlockchainLedgerWalletDetails | undefined;

}
