import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
 })
 export class UrlService {

  createMarketingSiteUrl(route: string) {
    return `${environment.marketingSite}${route}`;
  }

}
