import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { ServiceResult } from './results/service-result';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class MarketingService {
    constructor(
        private cookieService: CookieService,
        private httpClient: HttpClient
    ) { }

    /**
     * Processes query params for affiliate code logging
     * @param queryParams 
     * @param route 
     * @param userAgent 
     * @param isDev 
     */
    public async processAffiliateCode(queryParams: Params, route: string, userAgent: string, isDev: boolean = false) {
        for (const key in queryParams) {
            if (queryParams.hasOwnProperty(key) && key.toLowerCase() === 'refcode') {
                const codeQueryParam: string = queryParams[key].toString();
                if (codeQueryParam) {
                    const expiryDate = new Date();
                    expiryDate.setDate(expiryDate.getDate() + 7);

                    const hostName = isDev ? 'localHost': environment.hostName;
                    this.cookieService.set('refCode', codeQueryParam, expiryDate, '/', hostName);

                    const visit = { affiliateCode: codeQueryParam, route: route,  userAgent: userAgent };
                    await this.LogAffiliateVisit(visit);

                    break;
                }
            }
        }
    }

    /**
     * Sends Visit data to XspectarVerse Api
     * @param visit 
     */
    private async LogAffiliateVisit(visit: { affiliateCode: string; route: string; userAgent: string; }) {

        const requestBody = {
            affiliateCode: visit.affiliateCode,
            route: visit.route,
            userAgent: visit.userAgent
        };
    
        try {
            const response = this.httpClient.post<ServiceResult<string>>(`${environment.xSpectarVerseApiUrl}marketing/affiliate/visit`, requestBody
            );
            const result = await firstValueFrom(response);
            console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
        }
    }

}

