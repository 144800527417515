<h1 class="head head--xlarge">My Account</h1>
<p *ngIf="showAccountUserId()" class="accountId">Account Id: {{accountDetails?.accountGuid!.substr(accountDetails?.accountGuid!.length  - 10)}}</p>
<p>
  Welcome to your account! <br />
  From here you can view and manage your xSPECTAR portfolio
</p>

<app-navigation [menuItems]="menuItems"></app-navigation>

<div class="content" *ngFor="let item of menuItems">
  <div class="content__route">
    <a [routerLink]="item.link" routerLinkActive="active">
      {{ item.name }}
      <i class="fas fa-angle-down"
        [ngClass]="{'fa-angle-down': isActiveRoute(item.link), 'fa-angle-up': !isActiveRoute(item.link)}"></i>
    </a>
  </div>
  <div class="content__main">
    <router-outlet *ngIf="isActiveRoute(item.link)"></router-outlet>
  </div>
</div>

<div #modal class="modal fade" id="accountDetailModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div>
          <h5 class="head head--xsmall">Account details saved!</h5>

          <p>
            You have successfully updated your account details!
          </p>

          <button class="button button--dark" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
