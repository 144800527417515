import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecurityService } from '../services';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { XSpectarVerseService } from '../services/xSpectarVerse.service';
import { json } from 'stream/consumers';

@Injectable({
  providedIn: 'root',
})
export class SecurityInterceptor implements HttpInterceptor {

    constructor(private securityService: SecurityService, private xSpectarVerseService: XSpectarVerseService) { }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {


      // FS Auth calls
      if (httpRequest.url.startsWith(environment.apiUrl)) {
        if (this.securityService.isSignedIn && this.securityService.securityToken) {
          return next.handle(httpRequest.clone({
            headers: httpRequest.headers.set('Authorization', this.securityService.securityToken)
          }));
        }
      }

      if (httpRequest.url.startsWith(environment.xSpectarVerseApiUrl)) {

        // this uses the FS auth code to get a xSPECTARVerse one
        if (httpRequest.url == `${environment.xSpectarVerseApiUrl}security/signInFromFuelStack`) {
          if (this.securityService.isSignedIn && this.securityService.securityToken) {
            return next.handle(httpRequest.clone({
              headers: httpRequest.headers.set('Authorization', this.securityService.securityToken)
            }));
          }
        }

        // Normal xSPECTARVerse calls
        if (this.xSpectarVerseService.isSignedIn && this.xSpectarVerseService.securityToken) {
          let headers = httpRequest.headers.set('Authorization', this.xSpectarVerseService.securityToken);
        
          // Only if we are doing verify of Link code
          if (httpRequest.url.startsWith(`${environment.xSpectarVerseApiUrl}security/LinkCode/Verify`) && this.securityService.securityToken) {
            headers = headers.set('Fs_Authorization', this.securityService.securityToken);
          }

          return next.handle(httpRequest.clone({ headers: headers }));
        }
        
      }

      return next.handle(httpRequest);

    }
}
