export enum PurchaseProgressStatus {
    Failed,
    Complete,
    Processing,
    AwaitingConfirmation
}

export interface IPurchaseProgress {
    progressMessage: string,
    progressStatus: PurchaseProgressStatus,
    purchasedAssetGuids: string[] | undefined
}
  