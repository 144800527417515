import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { mapServiceResult, ServiceResult, ServiceResultStatus, NotificationService, NotificationType } from './';
import { IAccountEmailSubscriptions } from '../models';
import { environment } from 'src/environments/environment';
import { of, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { NotificationHubMessageTracking } from './hubs/notification-hub-message-tracking';
import { ISecurityIdentityDetails } from '../models/security/security-identity-details';
import { IAccountDetails } from '../models/security/account-details';
import { ISignInInstruction } from '../models/security/sign-in-instruction';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private securityService: SecurityService,
    private router: Router) { }

  canActivate() {
    if (this.securityService.isSignedIn) {
      return true;
    } else {
      return this.router.parseUrl('/');
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class SecurityService implements OnDestroy  {

  private clientGuid: string = uuidv4();
  private accountDetails: IAccountDetails | undefined;
  private securityConnection: HubConnection | undefined;
  private failureCount = 0;
  private notificationHubMessageTracking: NotificationHubMessageTracking;
  private keepAlive : any;

  isSignedIn: boolean = false;
  securityToken: string | undefined;

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private router: Router,
    ) {
      this.notificationHubMessageTracking = new NotificationHubMessageTracking(httpClient);
  }
  ngOnDestroy(): void {
    this.clearConnection();
  }

  // Setup the notification hub
  initialize() {
    let securityToken = sessionStorage.getItem('security_token');

    if (!securityToken) {
      securityToken = localStorage.getItem('security_token');
    }

    if (securityToken) {
      this.isSignedIn = true;
      this.securityToken = securityToken;
    }

    this.notificationService.initialize(securityToken);
    return of();
  }

  getClientGuid() {
    return this.clientGuid;
  }

  finaliseWalletTokenLogin(walletProviderGuid: string, securityIssuerProviderGuid: string, actionType: number, walletAccount: string, originalMessage: string, signedMessage: any, publicKey: string = "") {

    let _notificationType: NotificationType| undefined;
    let _notificationData: any;

    this.setupSecureConnection(
      (securityConnection: HubConnection) => {
        
        securityConnection.on('Notification', (messageGuid, notificationType, notificationData) => {

          this.notificationHubMessageTracking.ackMessage(messageGuid).subscribe();

          _notificationType = notificationType as NotificationType;
          _notificationData = notificationData

          let notificationDataAsObject = JSON.parse(notificationData);
          notificationDataAsObject = typeof notificationDataAsObject == "object" ? notificationDataAsObject : (JSON.parse(notificationDataAsObject.toString()) as any);

          if (notificationType === NotificationType.SignInApproved) 
          {
            this.setToken(notificationDataAsObject.token);
            this.getAccountDetails(true).subscribe()

            this.finalizeRequest(_notificationType, notificationDataAsObject);
            this.finalizeSignIn(_notificationType, walletProviderGuid, securityIssuerProviderGuid);
          }

          else {

            if (notificationType === NotificationType.SignInRejected) {
              this.clearToken();
              this.finalizeSignIn(_notificationType, walletProviderGuid, securityIssuerProviderGuid);
            }
            this.finalizeRequest(_notificationType, notificationDataAsObject);
            
          }

        });

      },
      () => {
        
        var actionData = {
          walletAccount : walletAccount,
          originalMessage: originalMessage,
          signedMessage : signedMessage,
          securityIssuerProviderGuid: securityIssuerProviderGuid,
          publicKey: publicKey
        };
    
        var data = {
          clientId: this.clientGuid,
          account: walletAccount,
          actionMetadata : {
            actionData : {
              tenantGuid : environment.tenantGuid,
              actionType : actionType,
              data : JSON.stringify(actionData),
            }
          }
    
        }

        switch (securityIssuerProviderGuid) {
          case environment.xdcProviderGuid:
          case environment.ethereumProviderGuid:
          case environment.flareProviderGuid:
          case environment.songbirdProviderGuid:
          case environment.bscProviderGuid:
            walletProviderGuid = environment.genericEvmWalletProviderGuid;
            break;        
          default:            
          break;
        }

        console.log(`this is ur endpoint: ${environment.providerApiUrl}providers/${walletProviderGuid}/execute`)
    
        this.httpClient.post<ServiceResult>(`${environment.providerApiUrl}providers/${walletProviderGuid}/execute`, data)
          .pipe(mapServiceResult)
          .subscribe();

      },
      () => {

      }

    );

  }
  setupAddCryptoWalletSecurityMonitor(runWhenReady: () => void) {

    let _notificationType: NotificationType| undefined;
    let _notificationData: any;

    this.setupSecureConnection(
      (securityConnection: HubConnection) => {
        
        securityConnection.on('Notification', (messageGuid, notificationType, notificationData) => {

          this.notificationHubMessageTracking.ackMessage(messageGuid).subscribe();

          _notificationType = notificationType as NotificationType;
          _notificationData = notificationData

          if (notificationType === NotificationType.AddCryptoWalletComplete ||
            notificationType === NotificationType.AddCryptoWalletFailed ||
            notificationType === NotificationType.AddCryptoWalletFailedMessage) 
          {
            this.finalizeRequest(_notificationType, _notificationData);
          }
        });

      },
      runWhenReady,
      () => {}
    );

  }

  // Sign In methods
  signIn(walletProviderGuid: string, securityIssuerProviderGuid: string) {

    let _notificationType: NotificationType| undefined;
    let _notificationData: any;

    this.setupSecureConnection(
      (securityConnection: HubConnection) => {
        securityConnection.on('Notification', (messageGuid, notificationType, notificationData) => {

          this.notificationHubMessageTracking.ackMessage(messageGuid).subscribe();

          _notificationType = notificationType as NotificationType;
          _notificationData = notificationData

          if (notificationType === NotificationType.SignInApproved) {

            let signInResponse = JSON.parse(notificationData);
            signInResponse = typeof signInResponse == "object" ? signInResponse : (JSON.parse(signInResponse.toString()) as any);

            this.setToken(signInResponse.token);
            this.getAccountDetails(true).subscribe()

            this.finalizeRequest(_notificationType, signInResponse);
            this.finalizeSignIn(_notificationType, walletProviderGuid, securityIssuerProviderGuid);

          }
          else if(notificationType === NotificationType.SignInRejected) {
            this.clearToken();

            this.finalizeSignIn(_notificationType, walletProviderGuid, securityIssuerProviderGuid);
          }

        });
      },
      () => {
        this.initializeSignIn(walletProviderGuid, securityIssuerProviderGuid);
      },
      () => {
        this.finalizeRequest(_notificationType, _notificationData);
        this.finalizeSignIn(_notificationType, walletProviderGuid, securityIssuerProviderGuid);
      }

    );

  }
  setToken(token: string) {
    this.isSignedIn = true;
    this.securityToken = token;
    localStorage.setItem('security_token', token);
  }
  clearToken() {
    this.isSignedIn = false;
    this.securityToken = undefined;
    localStorage.removeItem('security_token');
  }
  signOut(isSecureRoute: boolean) {
    this.accountDetails = undefined;
    this.clearToken();
    this.notificationService.send(NotificationType.SignOut, undefined);

    if (isSecureRoute) {
      this.router.navigate(['/']);
    }
  }

  private setupSecureConnection(handlers: (securityConnection: HubConnection) => void, onMessageTrackingSetup: () => void, onError: () => void) {

    this.clearConnection();
    this.securityConnection = new HubConnectionBuilder().configureLogging(LogLevel.Information).withUrl(`${environment.apiUrl}hubs/security`).withAutomaticReconnect().build();

    if (this.securityConnection) {
      this.securityConnection.start().then(() => {
        if (this.securityConnection) {

          this.securityConnection.onreconnected(error => {
            this.notificationHubMessageTracking.setupConnectionId(this.securityConnection!, this.clientGuid, environment.securityHubTypeGuid).subscribe();
          });

          handlers(this.securityConnection);

          this.notificationHubMessageTracking.setupConnectionId(this.securityConnection,this.clientGuid, environment.securityHubTypeGuid)
          .subscribe((result: any) => {
            if (result.status == ServiceResultStatus.Success) {
              onMessageTrackingSetup();
            }
          });

        }
      }).catch((error) => {
        onError();
      });
    }

  }

  // Account managgement methods
  
  getAccountDetails(forceReload: boolean = false) {
    
    if (!forceReload && this.accountDetails) {
      return of({ status: 200, data: this.accountDetails } as ServiceResult<IAccountDetails>);
    }

    return this.httpClient.get<ServiceResult<IAccountDetails>>(`${environment.apiUrl}account/current/details`)
      .pipe(mapServiceResult<IAccountDetails>)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success) {
          this.accountDetails = result.data;
        }
      }));
  }
  updateAccountDetails(accountDetails: IAccountDetails) {
    return this.httpClient.post<ServiceResult>(`${environment.apiUrl}account/current/details`, accountDetails)
      .pipe(mapServiceResult)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success) {
          // Todo: This call doesnt return a security details??
          this.accountDetails = accountDetails;
        }
      }));
  }
  getUserEmailSubscriptions() {
    return this.httpClient.get<ServiceResult<IAccountEmailSubscriptions>>(`${environment.apiUrl}account/GetAccountEmailSubscriptions`)
      .pipe(mapServiceResult<IAccountEmailSubscriptions>);
  }
  updateUserEmailSubscriptions(accountEmailSubscriptions: IAccountEmailSubscriptions) {
    return this.httpClient.post<ServiceResult>(`${environment.apiUrl}account/SaveAccountEmailSubscriptions`, accountEmailSubscriptions)
      .pipe(mapServiceResult);
  }
  hasXUser(accountDetails: IAccountDetails) : boolean {

    if (accountDetails.securityIdentityDetails == undefined)
    {
      return false;
    }

    return accountDetails
      .securityIdentityDetails
      .filter(e => e.providerGuid == environment.xUserProviderGuid)
      .length > 0;

  }
  
  hasSecurityIdentity(securityProviderGUid: string, accountDetails: IAccountDetails) : boolean {

    if (accountDetails.securityIdentityDetails == undefined)
    {
      return false;
    }

    return accountDetails
      .securityIdentityDetails.filter(e => e.providerGuid == securityProviderGUid)
      .length > 0 ?? false;
  }

  getWalletsForProvider(accountDetails: IAccountDetails, providerGuid : string) : ISecurityIdentityDetails[] | undefined {

    if (accountDetails.securityIdentityDetails == undefined)
    {
      return undefined;
    }

    return accountDetails
      .securityIdentityDetails
      .filter(e => e.providerGuid == providerGuid);
  }


  private initializeSignIn(walletProviderGuid: string, securityIssuerProviderGuid: string) {
    if (this.securityConnection) {

      this.keepAlive = this.notificationHubMessageTracking.setKeepAlive(this.securityConnection, this.keepAlive);

      this.httpClient.post<ServiceResult<ISignInInstruction>>(`${environment.apiUrl}security/sign-in`, { 
        clientGuid: this.clientGuid, 
        walletProviderGuid: walletProviderGuid, 
        securityIssuerProviderGuid: securityIssuerProviderGuid 
      })
        .pipe(mapServiceResult<ISignInInstruction>)
        .subscribe(result => {
          if(result.status === ServiceResultStatus.Success) {
            this.notificationService.send(NotificationType.SignInInitialized, result.data!);
          }
          else {
            this.finalizeSignIn(NotificationType.SignInRejected, walletProviderGuid, securityIssuerProviderGuid);
          }
        });
    }
  }

  private finalizeRequest(relayNotificationType: NotificationType| undefined, data: any | undefined) {

    if (relayNotificationType) {
      this.notificationService.send(relayNotificationType, data);
    }
    this.clearConnection();

  }
  private finalizeSignIn(notificationType: NotificationType| undefined, walletProviderGuid: string, securityIssuerProviderGuid: string) {

    if (notificationType === NotificationType.SignInRejected) {
      this.failureCount++;
      if (this.failureCount > 3) {
        this.failureCount = 0;
      } else {
        window.setTimeout(() => {
          this.signIn(walletProviderGuid, securityIssuerProviderGuid);
        }, 2000);
      }
    }
  }

  private clearConnection() {

    this.notificationHubMessageTracking.clearKeepAlive(this.keepAlive);

    if (this.securityConnection) {
      this.securityConnection.stop();
      this.securityConnection = undefined;
    }
  }

 }
