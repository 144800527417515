<div #container class="slide-container">
  <div [style.gridTemplateRows]="gridTemplateRows">
    <ng-content select="[first-slide]"></ng-content>
    <ng-content select="[second-slide]"></ng-content>
    <ng-content select="[third-slide]"></ng-content>
    <ng-content select="[fourth-slide]"></ng-content>
    <ng-content select="[fifth-slide]"></ng-content>
    <ng-content select="[sixth-slide]"></ng-content>
    <ng-content select="[seventh-slide]"></ng-content>
    <ng-content select="[eighth-slide]"></ng-content>
    <ng-content select="[nineth-slide]"></ng-content>
    <ng-content select="[tenth-slide]"></ng-content>
    <ng-content select="[eleventh-slide]"></ng-content>
    <ng-content select="[twelveth-slide]"></ng-content>
  </div>
</div>
