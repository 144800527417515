import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { QrCodeWalletTypes } from '../../enums/qr-code-wallet-types';
import { IHtmlElementTextureOptions } from '@babylonjs/core';

@Component({
  selector: 'app-scan-qr-code-v2',
  templateUrl: './scan-qr-code.component.html',
  styleUrls: ['./scan-qr-code.component.scss'],
})
export class ScanQrCodeComponentV2 implements OnInit {
  public QrCodeWalletTypes = QrCodeWalletTypes;

  @Input()
  qrCodeImgData: string | undefined;

  @Input()
  signInQrUrl: SafeResourceUrl | undefined;

  @Input()
  showFooterMessage: boolean = false;
  @Input()
  footerMessage: string | undefined;

  @Input()
  isNftModal: boolean = false;

  @Input()
  walletType: QrCodeWalletTypes | undefined;

  @Output()
  cancel = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  emitCancel(): void {
    this.cancel.emit();
  }
}
