import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetailsComponent } from './components/details/details.component';
import { AccountLayoutComponent } from './components/layout/layout.component';
import { AssetsComponent } from './components/assets/assets.component';
import { LandComponent } from './components/land/land.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { XsLockVaultComponent } from './components/xs-lock-vault/xs-lock-vault.component';
import { LinkAccountsComponent } from './components/link-accounts/link-accounts.component';
import { AuthGuard } from '../shared/services';
import { PaymentsComponent } from './components/payments/payments.component';


const routes: Routes = [
  {
    path: '',
    component: AccountLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'details',
        component: DetailsComponent,
      },
      {
        path: 'history',
        component: OrderHistoryComponent,
      },
      {
        path: 'xs-lock-vault',
        component: XsLockVaultComponent,
      },
      {
        path: 'land',
        component: LandComponent,
      },
      {
        path: 'nfts',
        component: AssetsComponent,
      },
      {
        path: 'link-accounts',
        component: LinkAccountsComponent,
      },
      {
        path: 'payments',
        component: PaymentsComponent,
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingNewModule { }
