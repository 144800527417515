import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { MarketingService } from './v2/shared/services/marketing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  hideFooter = true;
  isV2Route = true;
  isStyleSheetAdded = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private marketingService: MarketingService
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(routeEvent => {
      if (routeEvent instanceof NavigationEnd) {
        this.hideFooter = routeEvent.url.toLowerCase().includes('welcome');

        document.querySelector('.allow-scroll')?.scroll(0, 0);

        if (!this.isStyleSheetAdded) {
          this.document.querySelector('link[href="styles.css"]')?.remove();
          this.addStyleSheets('/stylesV2.css');
        }

        //Incase of debugging against localhost with dev/beta/prod config
        const isDev = this.document.baseURI.includes('localhost');
        this.marketingService.processAffiliateCode(this.route.snapshot.queryParams, this.document.documentURI, window.navigator.userAgent, isDev );
      }
    });
  }
 
  private addStyleSheets(src: string) {
    const link = this.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', src);

    this.document.head.appendChild(link);
  }
}
