import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { IAccountDetails, IAccountEmailSubscriptions } from 'src/app/v2/shared/models';
import { SecurityService, ServiceResultStatus } from 'src/app/v2/shared/services';
import { AccountSectionService } from 'src/app/v2/shared/services/account-section.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  detailsForm?: FormGroup;

  isLoading = true;
  isEditingUsername = false;
  isEditingEmail = false;
  subscriptions: IAccountEmailSubscriptions | undefined;
  userDetails?: IAccountDetails;

  get preferencesArray():FormArray{
    return <FormArray> this.detailsForm?.get('preferences');
  }

  constructor(
    private readonly securityService: SecurityService,
    private fb: FormBuilder,
    private accountSectionService: AccountSectionService) {
    }

  ngOnInit(): void {
    this.securityService.getAccountDetails(true).subscribe(result => {
      if (result.status === ServiceResultStatus.Success && result.data) {
        this.userDetails = result.data;
      }

      this.securityService
        .getUserEmailSubscriptions()
        .pipe(
          finalize(() => this.isLoading = false)
        )
        .subscribe((result) => {
          if (result.status === ServiceResultStatus.Success && result.data) {
            this.subscriptions = result.data;
          }

          this.setupForm();
      });
    });
  }

  setupForm() {
    this.detailsForm = this.fb.group({
      username: this.fb.control(this.userDetails?.accountUsername, [Validators.required]),
      emailAddress: this.fb.control(this.userDetails?.emailAddress, [Validators.required, Validators.email]),
      optInForMarketingEmails: this.fb.control(this.userDetails?.canSendMarketingEmails),
      preferences: this.fb.array(this.getPreferences()),
      accountGuid: this.fb.control(this.userDetails?.accountGuid, [Validators.required])
    });
  }

  getAccountId() {

    return this.userDetails?.accountGuid ?? "";

  }

  getPreferences(): FormGroup[] {
    return <FormGroup[]>this.subscriptions?.subscriptions.map(e => {
      return this.fb.group({
        emailTypeName:[e.emailTypeName],
        emailDescription:[e.emailDescription],
        emailTypeId:[e.emailTypeId],
        isEnabled:[e.isEnabled]
      });
    });
  }

  update() {
    // TODO: Can we combine these into one call so we cana update user and email preferences in one go
    if (this.detailsForm && this.detailsForm.valid) {
      let controls = this.detailsForm.controls;

      this.securityService.updateAccountDetails({
        accountUsername: controls['username'].value ?? '',
        emailAddress: controls['emailAddress'].value ?? '',
        canSendMarketingEmails: controls['optInForMarketingEmails'].value ?? false,
        userGuid: undefined,
        accountGuid: controls['accountGuid'].value ?? false,
        accountName: '',
        securityIdentityDetails: undefined,
        loggedInWithSecurityIdentityDetails: undefined,
        paymentInstrumentDetails: undefined
      }).subscribe(result => {

      });

      let hasChanges = false;

      controls['preferences'].value.forEach((c: any) => {
        const index = this.subscriptions?.subscriptions.findIndex(s => s.emailTypeId === c.emailTypeId);
        if (index !== undefined && this.subscriptions?.subscriptions[index]) {
          hasChanges = hasChanges || this.subscriptions.subscriptions[index].isEnabled !== c.isEnabled
          this.subscriptions.subscriptions[index].isEnabled = c.isEnabled;
        }
      });

      if (hasChanges) {
        this.securityService.updateUserEmailSubscriptions(this.subscriptions as IAccountEmailSubscriptions).subscribe(r => {});
      }

      this.accountSectionService.showAccountModal();

      this.isEditingEmail = false;
      this.isEditingUsername = false;
      this.detailsForm.markAsPristine();
      this.detailsForm.markAsUntouched();
    }
  }
}
