import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DefaultLoginComponent } from './components/login/login.component';
import { DefaultRoutingModule } from './default-routing.module';
import { SharedModuleV2 } from '../shared/shared.module';


@NgModule({
  declarations: [
    DefaultLoginComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModuleV2
  ],
  exports: [
    DefaultRoutingModule,
  ]
})
export class DefaultModule { }
