export enum NftAcceptBrokeredOfferProgressStatus {
    Failed,
    Complete,
    Processing,
    AwaitingConfirmation
}

export interface INftAcceptBrokeredOfferProgress {
    progressStatus: NftAcceptBrokeredOfferProgressStatus,
    transactionGuid: string
}
  