import { IPaymentInstrument } from "src/app/v2/account/models";
import { ISecurityIdentityDetails } from "./security-identity-details";

export interface IAccountDetails {

  accountGuid: string | undefined,
  userGuid: string | undefined,
  accountName: string | undefined,
  accountUsername: string | undefined,
  emailAddress: string,
  canSendMarketingEmails: boolean,
  securityIdentityDetails: ISecurityIdentityDetails[] | undefined
  paymentInstrumentDetails: IPaymentInstrument[] | undefined
  loggedInWithSecurityIdentityDetails: ISecurityIdentityDetails | undefined
  
}
