<div #modal class="modal fade" id="signInModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body modal-body--no-margin">
        <i class="fa-solid fa-xmark" (click)="clearSignIn(false)"></i>
        <div>
          <app-slide-v2 [slideIndex]="slideIndex" [slideCount]="12" class="app-slide">
            <div first-slide class="signin-qr">
              <span *ngIf="qrCodeImgData">
                Sign In to xSpectar.com with Xaman (Xumm)
              </span>

              <app-scan-qr-code-v2
                [walletType]="walletType"
                [showFooterMessage] = showFooterMessage
                [footerMessage]="loginModalFooterMessage"
                [signInQrUrl]="signInQrUrl"
                [qrCodeImgData]="qrCodeImgData"
                (cancel)="cancelSignIn()"
              ></app-scan-qr-code-v2>

              <p class="or-para">OR</p>

              <p class="footer-para">Have an account? <a (click)="switchSlide(2)">Login</a></p>
            </div>
            <div second-slide class="first-sign-in">
              <div class="text-center">
                <h2>
                  Welcome to xSpectar
                </h2>
                <p>
                  Take a moment to update your preferences so you can get the most our of the xSpectar experience
                </p>
              </div>
              <app-account-details-prompt *ngIf="showDetails" [isFirstSignIn]="true" (skipped)="clearSignIn(true)" (updated)="clearSignIn(true)"></app-account-details-prompt>
            </div>

            <div third-slide>
              <form [formGroup]="loginGroup" (submit)="login()">
                <div class="text-center">
                  <h2 class="head head--small">
                    Login
                  </h2>
                </div>
                <div>
                  <label>
                    <span class="visually-hidden">Email</span>
                    <input type="text" name="email" formControlName="email" placeholder="email"/>
                    <div *ngIf="formSubmitted && loginGroup.get('email')?.invalid" class="form-error">
                      <div *ngIf="loginGroup.get('email')?.errors?.['required']">
                        Please enter an email.
                      </div>
                    </div>
                    <div *ngIf="formSubmitted && loginGroup.get('email')?.invalid" class="form-error">
                      <div *ngIf="loginGroup.get('email')?.errors?.['email']">
                        Please enter a valid email.
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label>
                    <span class="visually-hidden">Password</span>
                    <input type="password" name="password" formControlName="password" placeholder="password" />
                    <div *ngIf="formSubmitted && loginGroup.get('password')?.invalid" class="form-error">
                      <div *ngIf="loginGroup.get('password')?.errors?.['required']">
                        Please enter your password.
                      </div>
                    </div>
                  </label>
                </div>

                <div *ngIf="formSubmitted && formError" class="form-error">
                  <div>
                    Email and/or Password is incorrect
                  </div>
                </div>

                <div class="forgot-password">
                  <a (click)="switchSlide(4)" class="link">Forgot password?</a>
                </div>

                <button type="submit" class="button button--primary" [ngClass]="{'button--disabled': !loginGroup.valid}">Login</button>
              </form>

              <p class="or-para">OR</p>

              <div class="login-logo-list">
                <span>
                  <img src="../../../../../assets/images/Xrpl-Logo.png" (click)="switchSlide(0)" >
                </span>
                <span>
                  <img src="../../../../../assets/images/Hedera-Logo.png" (click)="switchSlide(5)">
                </span>
                <span>
                  <img src="../../../../../assets/images/btc-logo.png"  (click)="switchSlide(6)">
                </span>
                <span>
                  <img src="../../../../../assets/images/xdc-logo.png" (click)="switchSlide(7)">
                </span>
                <span>
                  <img src="../../../../../assets/images/ethereum-logo.png" (click)="switchSlide(8)">
                </span>
                <span>
                  <img src="../../../../../assets/images/flare-logo.png" (click)="switchSlide(9)">
                </span>
                <span>
                  <img src="../../../../../assets/images/song-logo.png" (click)="switchSlide(10)">
                </span>
                <span>
                  <img src="../../../../../assets/images/BSC-logo.png" (click)="switchSlide(11)" >
                </span>
              </div>

              <p class="footer-para">Don't have an account? <a (click)="switchSlide(3)" class="link">Sign up</a></p>
            </div>

            <div fourth-slide>
              <app-register (responseEmitter)="registerSuccess($event)"></app-register>

              <p class="or-para">OR</p>

              <div class="login-logo-list">
                <span>
                  <img src="../../../../../assets/images/Xrpl-Logo.png" (click)="switchSlide(0)" >
                </span>
                <span>
                  <img src="../../../../../assets/images/Hedera-Logo.png" (click)="switchSlide(5)">
                </span>
                <span>
                  <img src="../../../../../assets/images/btc-logo.png"  (click)="switchSlide(6)">
                </span>
                <span>
                  <img src="../../../../../assets/images/xdc-logo.png" (click)="switchSlide(7)">
                </span>
                <span>
                  <img src="../../../../../assets/images/ethereum-logo.png" (click)="switchSlide(8)">
                </span>
                <span>
                  <img src="../../../../../assets/images/flare-logo.png" (click)="switchSlide(9)">
                </span>
                <span>
                  <img src="../../../../../assets/images/song-logo.png" (click)="switchSlide(10)">
                </span>
                <span>
                  <img src="../../../../../assets/images/BSC-logo.png" (click)="switchSlide(11)" >
                </span>
              </div>


              <p class="footer-para">Have an account? <a (click)="switchSlide(2)" class="link">Log in</a></p>
            </div>

            <div fifth-slide>
              <form [formGroup]="forgotPasswordGroup" (submit)="forgotPassword()" *ngIf="!forgotPasswordSuccess">
                <div class="text-center">
                  <h2 class="head head--small">
                    Forgot password
                  </h2>
                </div>
                <div>
                  <label>
                    <span class="visually-hidden">Email</span>
                    <input type="text" name="email" formControlName="email" placeholder="email"/>
                    <div *ngIf="formSubmitted && forgotPasswordGroup.get('email')?.invalid" class="form-error">
                      <div *ngIf="forgotPasswordGroup.get('email')?.errors?.['required']">
                        Please enter an email.
                      </div>
                    </div>
                    <div *ngIf="formSubmitted && forgotPasswordGroup.get('email')?.invalid" class="form-error">
                      <div *ngIf="forgotPasswordGroup.get('email')?.errors?.['email']">
                        Please enter a valid email.
                      </div>
                    </div>
                  </label>
                </div>

                <div *ngIf="formSubmitted && formError && formErrorMessage" class="form-error">
                  <div>
                    {{ formErrorMessage }}
                  </div>
                </div>

                <button type="submit" class="button button--primary" [ngClass]="{'button--disabled': !forgotPasswordGroup.valid}">Submit</button>
              </form>

              <p *ngIf="forgotPasswordSuccess">Reset password link sent</p>

              <p class="or-para">OR</p>

              <div class="login-logo-list">
                <span>
                  <img src="../../../../../assets/images/Xrpl-Logo.png" (click)="switchSlide(0)" >
                </span>
                <span>
                  <img src="../../../../../assets/images/Hedera-Logo.png" (click)="switchSlide(5)">
                </span>
                <span>
                  <img src="../../../../../assets/images/btc-logo.png"  (click)="switchSlide(6)">
                </span>
                <span>
                  <img src="../../../../../assets/images/xdc-logo.png" (click)="switchSlide(7)">
                </span>
                <span>
                  <img src="../../../../../assets/images/ethereum-logo.png" (click)="switchSlide(8)">
                </span>
                <span>
                  <img src="../../../../../assets/images/flare-logo.png" (click)="switchSlide(9)">
                </span>
                <span>
                  <img src="../../../../../assets/images/song-logo.png" (click)="switchSlide(10)">
                </span>
                <span>
                  <img src="../../../../../assets/images/BSC-logo.png" (click)="switchSlide(11)" >
                </span>
              </div>
              <p class="footer-para">Have an account? <a (click)="switchSlide(2)" class="link">Log in</a></p>
            </div>

            <div sixth-slide class="hashpack">
              
              <div class="walletStatus">
                <h3>HashPack Wallet</h3>

                <div class="provider">
                  <span>Status: </span>
                  <span>{{hashPackConnectionStatus}}</span>
                  <div *ngIf="hederaConnectedAccountConnected">
                    <span>Wallet: {{hederaConnectedAccount}}</span>
                  </div>

                  <div>
                    <button *ngIf="hashPackConnectionStatus == 'Paired'" class="button button--tertairy-alt" (click)="disconnectHashPack()">Disconnect</button>
                    <button *ngIf="hashPackConnectionStatus != 'Paired'" class="button button--tertairy-alt" (click)="pairHashpackWallet()">Pair-Wallet</button>
                    <button *ngIf="hederaConnectedAccountConnected" class="button button--tertairy-alt" (click)="signInWithEthereum('Hedera')">Sign In</button>
                  </div>

                </div>

              </div>

            </div>

            <div seventh-slide class="bitcoin">

              <div class="walletStatus bitcoin">
                <h3>Btc Wallet</h3>
          
                <div class="provider">
                  <span>UniSats Wallet</span>
                  <span>Status: </span>
                  <span>{{uniSatsConnectionStatus}}</span>
                  <div>
                    <span>Wallet: {{uniSatsWalletAddress | MiniEvmWalletAddress}}</span>
                  </div>
                  <div>
                    <button [disabled]="!uniSatsDetected" class="button button--primary" (click)="connectUniSatsWallet()">Pair UniSats wallet</button>
                    <button *ngIf="uniSatsWalletAddress" class="button button" (click)="loginWithUniSat()">Login with Bitcoin</button>
                  </div>
                </div>
                
                <!--
                <div class="provider">
                  <span>Xverse Wallet</span>
                  <span>Status: </span>
                  <span>{{satsConnectConnectionStatus}}</span>
                  <div>
                    <span>Wallet: {{satsConnectWalletAddress | MiniEvmWalletAddress}}</span>
                  </div>
                  <div>
                    <button [disabled]="!satsConnectDetected" class="button button--primary" (click)="connectUniSatsWallet()">Pair UniSats wallet</button>
                  </div>
                </div>
               -->
               
              </div>


            </div>

            
            <div eighth-slide>              
                <h3>XDC Wallet</h3>
                <div>
                  <span>Status: </span>
                  <span>{{genericEvmConnectionStatus}}</span>
                  <span style="color:red" *ngIf="isIncorrectChain(xdcChainId)"> Please switch network to XDC</span>
                </div>
                <div>
                  <span>Wallet: {{connectedXdcAccount | MiniEvmWalletAddress}}</span>
                </div>
                <div>
                  <button class="button button--secondary" (click)="connectEthereumWallet()">Pair with MetaMask</button>
                  <button *ngIf="connectedXdcAccount" class="button button--tertairy-alt" (click)="signInWithEthereum('Xdc')">Sign In</button>
                </div>              
            </div>

            <div nineth-slide>
                <h3>Ethereum Wallet</h3>
                <div>
                  <span>Status: </span>
                  <span>{{genericEvmConnectionStatus}}</span>
                  <span style="color:red" *ngIf="isIncorrectChain(ethereumChainId)"> Please switch network to Ethereum</span>
                </div>
                <div>
                  <span>Wallet: {{connectedEthereumAccount | MiniEvmWalletAddress}}</span>
                </div>
                <div>
                  <button class="button button--secondary" (click)="connectEthereumWallet()">Pair with MetaMask</button>
                  <button *ngIf="connectedEthereumAccount" class="button button--tertairy-alt" (click)="signInWithEthereum('Ethereum')">Sign In</button>
                </div>
            </div>         

            <div tenth-slide>
                <h3>Flare Wallet</h3>
                <div>
                  <span>Status: </span>
                  <span>{{genericEvmConnectionStatus}}</span>
                  <span style="color:red" *ngIf="isIncorrectChain(flareChainId)"> Please switch network to Flare</span>
                </div>
                <div>
                  <span>Wallet: {{connectedFlareAccount | MiniEvmWalletAddress}}</span>
                </div>
                <div>
                  <button class="button button--secondary" (click)="connectEthereumWallet()">Pair with MetaMask</button>
                  <button *ngIf="connectedFlareAccount" class="button button--tertairy-alt" (click)="signInWithEthereum('Flare')">Sign In</button>
                </div>
            </div> 
            <div eleventh-slide>
                <h3>Songbird Wallet</h3>
                <div>
                  <span>Status: </span>
                  <span>{{genericEvmConnectionStatus}}</span>
                  <span style="color:red" *ngIf="isIncorrectChain(songbirdChainId)"> Please switch network to Songbird</span>
                </div>
                <div>
                  <span>Wallet: {{connectedSongbirdAccount | MiniEvmWalletAddress}}</span>
                </div>
                <div>
                  <button class="button button--secondary" (click)="connectEthereumWallet()">Pair with MetaMask</button>
                  <button *ngIf="connectedSongbirdAccount" class="button button--tertairy-alt" (click)="signInWithEthereum('Songbird')">Sign In</button>
                </div>
            </div>
            <div twelveth-slide>
              <h3>Binance Smart Chain Wallet</h3>
              <div>
                <span>Status: </span>
                <span>{{genericEvmConnectionStatus}}</span>
                <span style="color:red" *ngIf="isIncorrectChain(bscChainId)"> Please switch network to Binance Smart Chain</span>
              </div>
              <div>
                <span>Wallet: {{connectedBscAccount | MiniEvmWalletAddress}}</span>
              </div>
              <div>
                <button class="button button--secondary" (click)="connectEthereumWallet()">Pair with MetaMask</button>
                <button *ngIf="connectedBscAccount" class="button button--tertairy-alt" (click)="signInWithEthereum('BSC')">Sign In</button>
              </div>
          </div>

          </app-slide-v2>

          <app-waiting [isWaiting]="!!waitingMessage" [message]="waitingMessage"></app-waiting>
        </div>
      </div>
  </div>
</div>
