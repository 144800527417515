import { IAssetSeries } from "../assets"

export interface IMaketplaceListingStatus {
    marketplaceListingStatusGuid: string,
    marketplaceListingStatusName: string
}

export interface IMaketplaceListingType {
    marketplaceListingTypeGuid: string,
    marketplaceListingTypeName: string
}

export interface IMarketplaceListing {
    marketplaceListingGuid: string,
    marketplaceListingStatus: IMaketplaceListingStatus,
    marketplaceListingType: IMaketplaceListingType,
    assetSeries: IAssetSeries,
    currencyGuid: string,
    paymentInstrumentProviderGuid: string,
    sell: boolean,
    acceptBids: boolean,
    acceptOffers: boolean,
    title: string,
    description: string,
    detail: string,
    start: Date,
    expires: Date,
    isLive: boolean,
    minimumBid: number | null,
    minimumOffer: number | null,
    salePrice: number | null,
    created: Date,
    createByGuid: string,
    createdByName: string,
    modified: Date | null,
    modifiedByGuid: string | null,
    modifiedByName: string | null
}
