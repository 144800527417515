import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UrlService } from '../../services/url.service';
import { AccountService } from '../../services/account.service';
import { ServiceResultStatus } from '../../services';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @Output() responseEmitter = new EventEmitter<any>();

  waitingMessage: string | undefined;
  registerGroup: FormGroup;

  formError: boolean = false;
  formSubmitted: boolean = false;
  formErrorMessage: string | undefined;
  forgotPasswordSuccess: boolean = false;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    public urlService: UrlService
  ) {
      this.registerGroup = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{6,}$/)]],
        terms: [false, Validators.requiredTrue]
      });
    }

  ngOnInit(): void {

  }

  register() {
    this.formSubmitted = true;
    this.formErrorMessage = undefined;
    this.formError = false;

    if (!this.registerGroup.valid) {
      return;
    }

    this.waitingMessage = 'Creating account';

    this.accountService.register({
      email: this.registerGroup.get('email')?.value,
      password: this.registerGroup.get('password')?.value,
      confirmPassword: this.registerGroup.get('password')?.value
    }).subscribe(response => {
      if (response.status !== ServiceResultStatus.Success) {
        this.formError = true;

        if (response.status === ServiceResultStatus.BadRequest) {
          this.formErrorMessage = 'Could not create account';
        }
      } else {
        this.responseEmitter.emit(response.data);
      }

      this.waitingMessage = undefined;
    });
  }
}
