<header class="header">
  <div class="header__left">
    <i class="fa-solid fa-bars" (click)="navigationOpen = !navigationOpen"></i>
   <a href="https://xspectar.com/">
    <img src="../../../assets/v2/images/logo.svg" alt="xSpectar" />
   </a>
   
  </div>
  <div class="header__middle">
    <nav>
      <ul>
        <li>
          <a href="https://xspectar.com">xSPECTAR.com</a>
        </li>

        
          <li>
            <a routerLink="buy-land">Buy Land</a>
          </li>
          <li>
            <a routerLink="land-picking">Land Picking</a>
          </li>

        <li>
          <a routerLink="bitmap-island-land-sale">Bitmap Island</a>
        </li>
        <li>
          <a routerLink="/xs-lock">XS-Lock</a>
        </li>
        <li>
          <a routerLink="/marketplace">Marketplace</a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="header__right">
    <!-- <span>
      <a routerLink="{{urlService.createMarketingSiteUrl('contact')}}">Contact</a>
    </span> -->

    <div *ngIf="isSignedIn" class="account-section" [ngClass]="{'account-section--show': showAccountDetails}">
      <span>
        <i class="fa-solid fa-circle-user" (click)="showAccountDetails = !showAccountDetails"></i>
        <span class="logged-in"></span>
      </span>

      <div class="account-section__details">
        <strong *ngIf="userName">{{ userName }}</strong>
        <span *ngIf="userEmail" class="account-section__email">{{ userEmail }}</span>

        <a routerLink="/account/details" class="button button--tertairy">Go to account</a>
        <a href="#" (click)="signOut()"><i class="fa-solid fa-arrow-right-from-bracket"></i>Sign out</a>
      </div>
    </div>

    <a *ngIf="!isSignedIn" class="button button--tertairy-alt" (click)="signIn()">Sign in</a>
  </div>
</header>

<app-navbar-v2 [navigationOpen]="navigationOpen" (close)="navigationOpen = false"></app-navbar-v2>
