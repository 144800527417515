import { Observable, Observer, Subject, Subscription } from "rxjs";;

export class NotificationResult<ImmediateResponse, NotificationResponse> {

  private response: Subject<NotificationResponse>;

  constructor(public immediateResponse: ImmediateResponse) {
    this.response = new Subject<NotificationResponse>();
  }

  subscribe(observer: (response: NotificationResponse) => void) {
    return this.response.asObservable().subscribe(observer);
  }

  setResult(notficationResponse: NotificationResponse) {
    this.response.next(notficationResponse);
    this.response.complete();
  }

  setNext(notficationResponse: NotificationResponse) {
    this.response.next(notficationResponse);
  }
}
export class NotificationResultNoResponse<NotificationResponse> {

  private response: Subject<NotificationResponse>;

  constructor() {
    this.response = new Subject<NotificationResponse>();
  }

  subscribe(observer: (response: NotificationResponse) => void) {
    return this.response.asObservable().subscribe(observer);
  }

  setResult(notficationResponse: NotificationResponse) {
    this.response.next(notficationResponse);
    this.response.complete();
  }

  setNext(notficationResponse: NotificationResponse) {
    this.response.next(notficationResponse);
  }
}
