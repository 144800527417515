import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import { SecurityService } from 'src/app/v2/shared/services';
import { UiService } from 'src/app/v2/shared/services/ui.service';

@Component({
  selector: 'app-default-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class DefaultLoginComponent implements OnInit {


  get isSignedIn() {
    return this.securityService.isSignedIn;
  }

  constructor(
    private router: Router,
    private readonly securityService: SecurityService,
    private uiService: UiService) {
    }

  ngOnInit(): void {
    this.securityService.getAccountDetails().subscribe(result => {
      if (!result.data) {
        this.signIn();
      } else {
        this.router.navigate(['/account/details'])
      }
    });
  }

  signIn() {
    this.uiService.openSignInModal();
  }

}
