<app-waiting
  [message]="'Loading nfts'"
  [isWaiting]="isLoading"
  [fullScreen]="false"
  *ngIf="isLoading">
</app-waiting>

<ng-container *ngIf="!isLoading">
  <div *ngIf="combinedAssets.length; else noAssets">
    <div class="nft-header">
      <p>You have {{combinedAssets.length}} NFTs in your account. To make and receive offers, access our native Marketplace.</p>

      <div class="button-group">
        <button class="button button--secondary" (click)="toggleNFTs()">Show my NFT's</button>
        <a class="button button--secondary" routerLink="/marketplace">Go to marketplace</a>
      </div>
    </div>

    <div class="search">
      <label>
        <span class="visually-hidden">Search</span>
        <input type="text" placeholder="Search by name, rank or trait" (keyup)="search($event)" />
      </label>

      <div class="view-type">
        <button type="button" title="grid" (click)="currentDisplayType = displayType.Grid" [ngClass]="{'active': currentDisplayType === displayType.Grid}">
          <img src="../assets/v2/images/grid.svg" alt="" *ngIf="currentDisplayType === displayType.Grid" />
          <img src="../assets/v2/images/grid_white.svg" alt="" *ngIf="currentDisplayType !== displayType.Grid" />
        </button>
        <button type="button" title="list" (click)="currentDisplayType = displayType.List" [ngClass]="{'active': currentDisplayType === displayType.List}"><i class="fa-solid fa-list"></i></button>
      </div>
    </div>

    <div class="nfts" [ngClass]="{'nfts--grid': currentDisplayType == displayType.Grid, 'nfts--list': currentDisplayType == displayType.List}">
      <div *ngFor="let asset of displayAssets" class="glass">
        <div>
          <h4>{{ asset.assetName }}</h4>
        </div>

        <img [src]="$any(asset.assetMetadata).agentImage" alt="" />
      </div>
    </div>

    <div class="nft-footer">
      <a class="button button--secondary" routerLink="/marketplace">Go to marketplace</a>
      <button class="button button--secondary" (click)="showMore()" *ngIf="displayAssets.length < combinedAssets.length">Show more</button>
    </div>

    <div class="mobile-overlay" [ngClass]="{'mobile-overlay--show': showMobileNfts}">
      <h4 class="head head--small">
        My NFTs
        <i class="fa-solid fa-xmark" (click)="toggleNFTs()"></i>
      </h4>


      <div class="search">
        <label>
          <span class="visually-hidden">Search</span>
          <input type="text" placeholder="Search by name, rank or trait" (keyup)="search($event)" />
        </label>
      </div>

      <div class="nfts nfts--mobile">
        <div *ngFor="let asset of combinedAssets" class="glass">
          <div>
            <h4>{{ asset.assetName }}</h4>
            <span>Ranking {{ getAssetAttributeValue(asset, "Ranking") }}</span>
          </div>

          <img [src]="$any(asset.assetMetadata).agentImage" alt="" />
        </div>
      </div>
    </div>
  </div>

  <ng-template #noAssets>
    <p class="empty-state-para">We're sorry! You dont have any NFTs</p>

    <a class="button button--secondary" routerLink="/marketplace">Go to marketplace</a>
  </ng-template>
</ng-container>
