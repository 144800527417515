import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar-v2',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  environment: any;
  
  @Input()
  navigationOpen = false;

  @Output()
  close = new EventEmitter<boolean>();

  constructor() {
    this.environment = environment;
  }

  ngOnInit(): void {
  }

  closeNavbar() {
    this.close.emit();
  }
}
