import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
 })
 export class AccountSectionService {
  // Observable source
  private _modalSource = new BehaviorSubject<boolean>(false);
  // Observable stream
  modalSource = this._modalSource.asObservable();
  // service command
  showAccountModal() {
    this._modalSource.next(true);
  }
}
