import { IBlockchainLedgerWalletDetails } from "./blockchain-ledger-wallet-details";
import { IBlockchainNftAttributeCollectionSummary } from "./blockchain-nft-attribute-collection-summary";
import { IBlockchainNftAttributeSummary } from "./blockchain-nft-attribute-summary";
import { IBlockchainNftOfferDetails } from "./blockchain-nft-nffer-details";

export interface IXls20NftDetails {

      nftDataGuid : string;
      nftokenId : string;
      metaDataJson : string | undefined;

      image: string | undefined;
      video: string | undefined;
      issuerWalletDetails: IBlockchainLedgerWalletDetails;
      ownerWalletDetails: IBlockchainLedgerWalletDetails | undefined;

      taxon: string | undefined;
      name : string | undefined;
      description: string | undefined;

      sellOffers: IBlockchainNftOfferDetails[];
      buyOffers : IBlockchainNftOfferDetails[];

      hostedResource: string | undefined;

      isOwnedByUser: boolean;

      attributes : IBlockchainNftAttributeSummary[];

}
