import { IAssetAttribute } from "./asset-attribute";
import { IAssetMarkplaceStatus } from "./asset-marketplace-status";
import { IAssetResource } from "./asset-resource";
import { IAssetSeries } from "./asset-series";
import { IAssetType } from "./asset-type";
import { IAgentAssetMetadata, ILandSaleAssetMetadata } from "./asset-types";
import { IFreeLandPreMintAssetMetadata } from "./asset-types/free-land-pre-mint-metadata";
import { IRewardAssetMetadata, IVaultAssetMetadata } from "./asset-types/vault-asset-metadata";

export interface IAsset
{
  assetGuid: string;
  assetName: string;
  assetTypeGuid: string;
  assetSeries: IAssetSeries;
  assetResources: IAssetResource[];
  assetMetadata: IAgentAssetMetadata | IVaultAssetMetadata | IRewardAssetMetadata | IFreeLandPreMintAssetMetadata | ILandSaleAssetMetadata;
  marketplaceStatus: IAssetMarkplaceStatus;
  assetAttributes: IAssetAttribute[];
  assetStatusGuid: string;
  created: Date;

}
