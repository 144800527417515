import {  Component, OnInit } from '@angular/core';
import { SecurityService, ServiceResultStatus } from 'src/app/v2/shared/services';
import { AccountService } from 'src/app/v2/shared/services/account.service';
import { environment } from 'src/environments/environment';
import { IPaymentInstrument } from '../../models';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  paymentInstruments: IPaymentInstrument[] | undefined;
  xrplPaymentInstruments: IPaymentInstrument[] | undefined;
  hederaPaymentInstruments: IPaymentInstrument[] | undefined;
  stripePaymentInstruments: IPaymentInstrument[] | undefined;

  constructor(
    private securityService: SecurityService,
    private accountService: AccountService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.getSecurityDetails();

  }

  getSecurityDetails(forceReload: boolean = false) {

    this.securityService.getAccountDetails(forceReload).subscribe(result => {

      if (result.status === ServiceResultStatus.Success && result.data) {

        this.paymentInstruments = result.data.paymentInstrumentDetails;

        this.xrplPaymentInstruments = this.paymentInstruments?.filter(e => e.paymentInstrumentIssuerGuid == environment.xrplPaymentInstrumentIssuerGuid);
        this.hederaPaymentInstruments = this.paymentInstruments?.filter(e => e.paymentInstrumentIssuerGuid == environment.hederaPaymentInstrumentIssuerGuid);
        this.stripePaymentInstruments = this.paymentInstruments?.filter(e => e.paymentInstrumentIssuerGuid == environment.stripePaymentInstrumentIssuerGuid);

      }
    });
  }

  makePrimary(wallet: IPaymentInstrument, allWalletsOfType: IPaymentInstrument[]) {

        this.accountService.updatePrimaryPaymentInstrument(wallet).subscribe(result => {
          if (!result) {
            return;
          }

          allWalletsOfType.forEach(e => e.isPrimary = e.paymentInstrumentGuid == wallet.paymentInstrumentGuid)
        
        })

  }

  async removeSecurityIdentity(securityIdentityGuid: string) {

    this.accountService.removeCrytoWallet(securityIdentityGuid).subscribe(result => {

      setTimeout(() => {
        this.getSecurityDetails(true);
      }, 500);
      

    });

  }



}

