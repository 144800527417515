import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { mapServiceResult, ServiceResult } from '../services';

@Injectable({
  providedIn: 'root',
 })
 export class CommunicationService {

  constructor(private readonly httpClient: HttpClient) { }

  sendContactEnquiry(name: string, emailAddress: string, message: string) {
    return this.httpClient
      .post<ServiceResult>(`${environment.apiUrl}communication/contact`, { contactName: name, emailAddress: emailAddress, message: message })
      .pipe(mapServiceResult);
  }
 }
