export interface IAccountEmailSubscriptions {
  accountGuid: string,
  tenantId: number
  subscriptions: IEmailSubscription[]
}

export interface IEmailSubscription {
  emailTypeId: number,
  isEnabled: boolean,
  emailTypeName: string,
  emailDescription: string
}
