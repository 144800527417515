export enum NftAcceptOfferProgressStatus {
    Failed,
    Complete,
    Processing,
    AwaitingConfirmation
}

export interface INftAcceptOfferProgress {
    progressStatus: NftAcceptOfferProgressStatus,
    transactionGuid: string
}
  